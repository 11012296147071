export enum SocialMediaIcons {
  facebook = 'facebook-f',
  instagram = 'instagram',
  twitter = 'twitter',
  tiktok = 'tiktok',
  linkedin = 'linkedin-in',
}

export type FooterSocialLinkProps = {
  name: keyof typeof SocialMediaIcons;
  url: string;
};

export type FooterSocialProps = {
  title?: React.ReactNode;
  children?: React.ReactNode;
};
