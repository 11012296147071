import React from 'react';
import { defaultTheme, Icon, TypographyOld as Typography } from '@clikaliatech/clikalia-ui';

import { SubtitleSectionProps } from './SubtitleSection.types';

import { SubtitleSectionWrapper, SubtitleStyled, BackButtonStyled } from './SubtitleSection.styled';

const SubtitleSection: React.FC<SubtitleSectionProps> = ({ open, subtitle, backTitle, onBack }) => {
  return (
    <SubtitleSectionWrapper open={open}>
      {subtitle && (
        <SubtitleStyled type={Typography.Types.B2Regular} color={defaultTheme.palette.lightSalmon}>
          {subtitle}
        </SubtitleStyled>
      )}
      <BackButtonStyled
        tabIndex={0}
        onClick={onBack}
        type={Typography.Types.B2Medium}
        color={defaultTheme.palette.lightSalmon}
      >
        <Icon name="chevron-left" color={Icon.Colors.WHITE} variant={Icon.Variants.SHARP} />
        {backTitle}
      </BackButtonStyled>
    </SubtitleSectionWrapper>
  );
};

export default SubtitleSection;
