import styled from 'styled-components';

export const FooterSectionDesktopStyled = styled.div(({ theme }) => ({
  display: 'none',

  [theme.breakpoints.up('md')]: {
    display: 'block',
  },
}));

export const FooterSectionStyled = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: theme.spacing(2),
  '*': {
    color: theme.palette.white,
  },
}));

export const FooterSectionTitleStyled = styled.div(({ theme }) => ({
  textDecoration: 'none',
  fontWeight: 700,
  fontSize: theme.size(2.2),
  textTransform: 'uppercase',
  textAlign: 'center',
  width: '100%',

  [theme.breakpoints.up('sm')]: {
    textAlign: 'left',
  },
}));

export const FooterSectionLinkStyled = styled.a(({ theme }) => ({
  textDecoration: 'none',
  fontWeight: 500,
  fontSize: theme.size(1.6),
}));

export const FooterSectionMobileStyled = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: theme.spacing(1),
  '*': {
    color: theme.palette.white,
  },

  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
}));

export const FooterSectionAccordionTitleStyled = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  gap: theme.spacing(1),
  margin: 'auto',

  p: {
    margin: 0,
    textTransform: 'uppercase',
  },
}));

export const FooterSectionAccordionBodyStyled = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: theme.spacing(1),
  margin: theme.spacing(1, 0),

  p: {
    margin: theme.spacing(0.75),
  },
}));
