import styled from 'styled-components';

const FooterLogoWrapperStyled = styled.div(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  [theme.breakpoints.up('md')]: {
    justifyContent: 'initial',
  },
}));

const FooterLogoAnchorStyled = styled.a(({ theme }) => ({
  cursor: 'pointer',
  svg: {
    width: '73px',
    height: '19px',
    [theme.breakpoints.up('md')]: {
      width: '284px',
      height: '74px',
    },
  },
}));

export { FooterLogoWrapperStyled, FooterLogoAnchorStyled };
