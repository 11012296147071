import { useContext } from 'react';

import { FooterContext } from '../../../../Footer.context';
import { FooterAbstractStyled } from './FooterAbstract.styled';
import { FooterAbstractProps } from './FooterAbstract.types';

const FooterAbstract: React.FC<FooterAbstractProps> = ({ children }) => {
  const { testId } = useContext(FooterContext);

  if (!children) return null;

  return <FooterAbstractStyled data-testid={`${testId}Abstract`}>{children}</FooterAbstractStyled>;
};

FooterAbstract.displayName = 'footerAbstract';

export default FooterAbstract;
