export enum Section {
  sales = 'sales',
  rent = 'rent',
  acquisitions = 'acquisitions',
  mortgage = 'mortgage',
}

export interface HomeCoverProp {
  section: Section;
  backgroundImage?: string;
  onClick: () => void;
  testId: string;
}
