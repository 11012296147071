import { Children } from 'react';
import {
  defaultTheme,
  Icon,
  StepIndicator,
  useBreakpoint,
  useIsLandscape,
  useMediaQuery,
} from '@clikaliatech/clikalia-ui';
import { GeneralContextProvider, TabsHome } from 'clikalia-web-components/src';
import useControlSlider from 'clikalia-web-components/src/hooks/useControlSlider';
import HomeFooter from './components/HomeFooter';
import HomeHeader from './components/HomeHeader';
import SectionsSlider from './components/SectionsSlider';
import { HeaderLayoutStyled, ScrollDownButton, StepIndicatorContainer } from './HomeLayout.styled';
import { HomeLayoutProps } from './HomeLayout.types';

const HomeLayout = ({
  children,
  siteConfig,
  tabs,
  onClickButton,
  ctaButtonText,
  showMobileCtaAt,
  hideDesktopCtaAt,
  faqsIndexAt,
  verticalPathname = '/',
  tabsTestId = 'homeLayoutTab',
}: HomeLayoutProps) => {
  const { slide, onDotsChange, dotActive, onSlideChange } = useControlSlider({
    sliders: Children.count(children),
    initialState: 1,
  });

  /**
   * Show transparent header when:
   * 1. First Slide: dotActive equals 1 (0 is Header)
   * 2. Footer: dotActive equals Children length + 1
   */

  const isLandscape = useIsLandscape();

  const isMobileLg = useMediaQuery('lg', true);

  const isMobileLgLandscape = isLandscape && isMobileLg;

  const isHeaderOrFooterSlider = dotActive === Children.count(children) + 1 || dotActive === 1;
  const isHeaderSlider = dotActive === 1;

  const { isMD } = useBreakpoint();
  const shouldShowMobileCTA = showMobileCtaAt && showMobileCtaAt.includes(dotActive);
  const shouldHideDesktopCTA = hideDesktopCtaAt && hideDesktopCtaAt.includes(dotActive);

  return (
    <GeneralContextProvider initialSiteConfig={siteConfig}>
      <>
        <SectionsSlider
          currentSlide={slide}
          onChangeSlide={onDotsChange}
          onInnerChangeSlideEnd={onSlideChange}
        >
          <HeaderLayoutStyled transparent={isHeaderSlider && !isMobileLgLandscape}>
            <HomeHeader
              transparent={isHeaderSlider && !isMobileLgLandscape}
              pathname={verticalPathname}
            />
          </HeaderLayoutStyled>

          {children}
          <HomeFooter />
          {!isHeaderOrFooterSlider && (
            <StepIndicatorContainer>
              <StepIndicator
                steps={Children.count(children)}
                activeStep={dotActive - 1}
                inactiveColor={defaultTheme.palette.gray30}
                testId={`stepIndicator-sectionsSlider`}
                dotsGap={defaultTheme.spacing(1.5)}
                vertical
              />
            </StepIndicatorContainer>
          )}
        </SectionsSlider>
        {isHeaderSlider && (
          <ScrollDownButton aria-label="scroll-down-btn" onClick={() => onSlideChange(slide + 1)}>
            <Icon name="chevrons-down" variant={Icon.Variants.SHARP} color={Icon.Colors.WHITE} />
          </ScrollDownButton>
        )}
        {((isMD && !isHeaderOrFooterSlider) || (!isMD && shouldShowMobileCTA)) && (
          <TabsHome
            hideCta={shouldHideDesktopCTA}
            tabs={tabs}
            onClickButton={onClickButton}
            onChangeSlide={onSlideChange}
            activeStep={dotActive - 1}
            buttonText={ctaButtonText}
            faqsIndexAt={faqsIndexAt}
            testId={tabsTestId}
          />
        )}
      </>
    </GeneralContextProvider>
  );
};

export default HomeLayout;
