import React from 'react';
import { Button, TypographyOld as Typography, defaultTheme } from '@clikaliatech/clikalia-ui';

import InformationList from '../InformationList';
import { CoverContentProps } from './Content.types';
import { CoverContentWrapper, CoverDetailsWrapper, TitleStyled } from './Content.styled';

const Content: React.FC<CoverContentProps> = ({
  title,
  ctaLabel,
  informationList,
  onClick,
  testId,
}) => {
  return (
    <CoverContentWrapper>
      <TitleStyled type={Typography.Types.H3} color={defaultTheme.palette.white}>
        {title}
      </TitleStyled>
      <CoverDetailsWrapper>
        <Button
          id={testId}
          testId={testId}
          size={Button.Sizes.Medium}
          variant={Button.Variants.Primary}
          onClick={onClick}
          fluid
        >
          {ctaLabel}
        </Button>
        <InformationList informationList={informationList} />
      </CoverDetailsWrapper>
    </CoverContentWrapper>
  );
};

export default Content;
