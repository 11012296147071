import React from 'react';

import { ImageSlider as Slider, StepIndicator } from '@clikaliatech/clikalia-ui';

import useControlSlider from '../../../../../hooks/useControlSlider';

import { RestrictedStyling } from '../../../../../types/commons';

import ViewHowWork from '../ViewHowWork';

import { StepIndicatorWrapper, ViewHowWorkSlidersWrapper } from './ViewHowWorkSliders.styled';
import { ViewHowWorkSlidersProps } from './ViewHowWorkSliders.types';

const ViewHowWorkSliders: React.FC<RestrictedStyling<ViewHowWorkSlidersProps>> = ({
  testId = 'viewHowWorkSlidersComponent',
  dataSource = [],
}) => {
  const size = dataSource.length;

  const { onSlideChange, onDotsChange, slide, dotActive } = useControlSlider({
    sliders: size,
  });

  const isContainsMoreThanOneSlide = size > 1;

  return (
    <ViewHowWorkSlidersWrapper data-testid={testId}>
      <Slider
        onChangeSlide={onDotsChange}
        onInnerChangeSlideEnd={onSlideChange}
        currentSlide={slide}
      >
        {dataSource.map((params) => {
          const { title, description, pictureActive } = params;
          return (
            <ViewHowWork
              key={title}
              active
              title={title}
              description={description}
              picture={pictureActive}
              testId={testId}
              alt={title}
            />
          );
        })}
      </Slider>

      {isContainsMoreThanOneSlide && (
        <StepIndicatorWrapper>
          <StepIndicator
            activeStep={dotActive - 1}
            steps={size}
            testId={`${testId}-stepIndicator`}
          />
        </StepIndicatorWrapper>
      )}
    </ViewHowWorkSlidersWrapper>
  );
};

export default ViewHowWorkSliders;
