import { BannerSize } from 'cross-config/models/clikaliaCms.interface';
import { createContext, useCallback, useMemo } from 'react';
import { BannersContextProps, BannersContextProviderProps } from './BannersContext.types';

export const BannersContext = createContext<BannersContextProps>({
  banners: [],
  selectBanner: () => [],
  renderBannerComponentBySize: () => () => <></>,
});

const BannersContextProvider = ({
  children,
  banners,
  bannerComponents,
}: BannersContextProviderProps) => {
  const selectBanner = useCallback(
    (position: string) => {
      const selectedBanner = banners.filter((b) => position === b.position && b.active);

      if (undefined) return [];
      return selectedBanner;
    },
    [banners]
  );

  const renderBannerComponentBySize = useCallback(
    (size: BannerSize) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return bannerComponents[size] as React.ComponentType<any>;
    },
    [bannerComponents]
  );

  const contextValue = useMemo<BannersContextProps>(
    () => ({
      banners,
      selectBanner,
      renderBannerComponentBySize,
    }),
    [banners, renderBannerComponentBySize, selectBanner]
  );

  return <BannersContext.Provider value={contextValue}>{children}</BannersContext.Provider>;
};

export default BannersContextProvider;
