import React, { useMemo, useEffect, useState, useCallback } from 'react';
import useEmblaCarousel from 'embla-carousel-react';

import ControlSliderDots from '../ControlSliderDots';
import ControlSliderArrows from '../ControlSliderArrows';

import { CarouselProps } from './Carousel.types';
import {
  CarouselGrid,
  CarouselWrapper,
  CarouselLayout,
  CarouselReviewsContainer,
  CarouselReviewsSlider,
} from './Carousel.styled';

const Carousel: React.FC<CarouselProps> = ({ children, testId }) => {
  const [emblaRef, emblaApi] = useEmblaCarousel({ loop: true });

  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  const handleOnPrev = useCallback(() => {
    if (!emblaApi) {
      return;
    }

    emblaApi.scrollPrev();
  }, [emblaApi]);

  const handleOnNext = useCallback(() => {
    if (!emblaApi) {
      return;
    }

    emblaApi.scrollNext();
  }, [emblaApi]);

  const onSelect = useCallback(() => {
    if (!emblaApi) {
      return;
    }

    setActiveSlideIndex(emblaApi.selectedScrollSnap());
  }, [emblaApi, setActiveSlideIndex]);

  useEffect(() => {
    if (!emblaApi) {
      return;
    }

    onSelect();

    emblaApi.on('select', onSelect);
    emblaApi.on('reInit', onSelect);

    return () => {
      emblaApi.off('select', onSelect);
      emblaApi.off('reInit', onSelect);
    };
  }, [emblaApi, onSelect]);

  const sliders = useMemo(() => React.Children.toArray(children), [children]);
  const isContainsMoreThanOneSlide = sliders.length > 1;

  return (
    <CarouselWrapper>
      <CarouselGrid>
        <CarouselLayout ref={emblaRef} data-testid={testId}>
          <CarouselReviewsContainer>
            {sliders.map((child, key) => (
              <CarouselReviewsSlider key={key} active={activeSlideIndex === key}>
                {child}
              </CarouselReviewsSlider>
            ))}
          </CarouselReviewsContainer>
        </CarouselLayout>
      </CarouselGrid>
      {isContainsMoreThanOneSlide && (
        <ControlSliderArrows onNext={handleOnNext} onPrev={handleOnPrev} />
      )}
      {isContainsMoreThanOneSlide && (
        <ControlSliderDots activeStep={activeSlideIndex} steps={sliders.length} />
      )}
    </CarouselWrapper>
  );
};

export default Carousel;
