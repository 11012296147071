import styled from 'styled-components';
import { FooterProps } from './ViewHowWork.types';

export const ViewHowWorkWrapper = styled.div({
  display: 'grid',
  justifyItems: 'center',
});

export const Content = styled.section(({ theme }) => ({
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),

  img: {
    width: '100%',
    objectFit: 'contain',
    height: '20vh',
  },

  [theme.breakpoints.up('sm')]: {
    img: {
      height: 'auto',
    },
  },
}));

export const Footer = styled.footer<FooterProps>(({ active, theme }) => ({
  maxWidth: '300px',
  textAlign: 'center',
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  'p: first-child': {
    paddingBottom: theme.spacing(1),
    opacity: active ? 1 : 0.5,
  },
}));
