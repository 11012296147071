import { TypographyOld as Typography } from '@clikaliatech/clikalia-ui';
import styled from 'styled-components';

export const HomeAcquisitionsCounterStyled = styled.div(({ theme }) => ({
  width: '100%',
  height: '100%',
  margin: 'auto',
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'center',
  justifyContent: 'center',
  paddingTop: theme.spacing(8),
  paddingBottom: theme.spacing(8),

  [theme.breakpoints.up('lg')]: {
    maxWidth: '1200px',
    padding: 0,
  },
}));

export const HomeAcquisitionsTitle = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  [theme.breakpoints.up('sm')]: {
    ...theme.typography.h3,
  },
}));

export const HomeAcquisitionsCounterCardContainer = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: theme.spacing(3, 5),
  width: '100%',
  gap: theme.spacing(2),
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
    gap: theme.spacing(4),
    zIndex: 1,
  },

  [theme.breakpoints.down('lg', 'landscape')]: {
    padding: theme.spacing(3, 2),
  },
}));

export const HomeAcquisitionsCounterCard = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  background: theme.palette.lightSalmon,
  padding: '1.5vh',
  borderRadius: 11,
  width: '100%',
  textAlign: 'center',
  [theme.breakpoints.up('sm')]: {
    boxShadow: '0px 4px 5px rgba(0, 0, 0, 0.30)',
    padding: theme.spacing(4),
    maxWidth: 270,
  },
}));

export const HomeAcquisitionsCounterImageWrapper = styled.div(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.up('sm')]: {
    display: 'inline',
    position: 'relative',
    marginTop: theme.spacing(-10),
    marginBottom: theme.spacing(0.5),
    width: '100%',
    height: 330,
  },
}));

export const HomeAcquisitionsCounterImage = styled.img({
  objectPosition: 'center',
  objectFit: 'cover',
  borderRadius: 10,
  position: 'absolute',
  height: '100%',
  width: '100%',
  inset: '0px',
});
