import styled from 'styled-components';
import { TypographyOld as Typography } from '@clikaliatech/clikalia-ui';

const CoverContentWrapper = styled.section(({ theme }) => ({
  display: 'grid',
  gap: '5px',
  gridTemplateColumns: '.75fr',
  justifyContent: 'center',
  width: '100%',
  alignItems: 'center',
  alignContent: 'center',
  maxWidth: 'inherit',

  [theme.breakpoints.up('sm')]: {
    h3: {
      ...theme.typography.h2,
      whiteSpace: 'break-spaces',
    },
  },
}));

const TitleStyled = styled(Typography)(({ theme: { spacing, breakpoints } }) => ({
  textAlign: 'center',
  margin: spacing(3.25, 0, 1, 0),
  padding: spacing(0, 2),
  minHeight: 'auto',

  [breakpoints.up('sm')]: {
    whiteSpace: 'pre',
    margin: 0,
    padding: spacing(2, 2, 1, 2),
  },
}));

const CoverDetailsWrapper = styled.section(({ theme: { breakpoints, spacing } }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr',
  justifyContent: 'center',
  height: '120px',
  padding: spacing(0, 0.5),
  [breakpoints.up('sm')]: {
    gridTemplateColumns: '.8fr',
    height: 'auto',
  },
}));

export { CoverContentWrapper, CoverDetailsWrapper, TitleStyled };
