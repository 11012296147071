import React from 'react';
import { TypographyOld as Typography, defaultTheme, Icon } from '@clikaliatech/clikalia-ui';

import OverflowedWrapper from '../OverflowedWrapper';
import { MobileContentProps } from './MobileContent.types';
import { Wrapper, DescriptionWrapper } from './MobileContent.styled';

const MobileContent: React.FC<MobileContentProps> = ({ title, description }) => {
  return (
    <Wrapper>
      <Typography type={Typography.Types.B1Bold} color={defaultTheme.palette.white}>
        {title}
      </Typography>
      <DescriptionWrapper>
        <OverflowedWrapper
          maxHeight={'270px'}
          icon={
            <Icon name="chevron-down" color={Icon.Colors.WHITE} variant={Icon.Variants.SHARP} />
          }
        >
          <Typography type={Typography.Types.B3Regular} color={defaultTheme.palette.lightSalmon}>
            {description}
          </Typography>
        </OverflowedWrapper>
      </DescriptionWrapper>
    </Wrapper>
  );
};

export default MobileContent;
