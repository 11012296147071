import React, { useMemo, useState } from 'react';
import { Icon, ImageSlider as Slider } from '@clikaliatech/clikalia-ui';

import useControlSlider from '../../../../hooks/useControlSlider';
import { RestrictedStyling } from '../../../../types/commons';

import Banner from './components/Banner';
import ControlSliderDots from './components/ControlSliderDots';
import ControlSliderArrows from './components/ControlSliderArrows';

import { BannerAdsMarketingMiddleProps } from './BannerAdsMarketingMiddle.types';
import {
  BannerAdsMarketingMiddleWrapper,
  BannerAdsMarketingMiddleIconWrapper,
} from './BannerAdsMarketingMiddle.styled';

const BannerAdsMarketingMiddle: React.FC<RestrictedStyling<BannerAdsMarketingMiddleProps>> = ({
  dataSource = [],
  testId = 'bannersComponent',
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const slidesAvailable = useMemo(() => {
    return dataSource.filter((banner) => Boolean(banner.active));
  }, [dataSource]);

  const size = slidesAvailable.length;

  const { onSlideChange, onDotsChange, onNext, onPrev, slide, dotActive } = useControlSlider({
    sliders: size,
  });

  const isContainsMoreThanOneSlide = size > 1;

  const handleOnNext = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();
    event.stopPropagation();
    onNext();
  };

  const handleOnPrev = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();
    event.stopPropagation();
    onPrev();
  };

  const handleOnOpenLink = (url: string) => {
    window.open(url, '_blank');
  };

  if (!isOpen) return null;
  return (
    <BannerAdsMarketingMiddleWrapper data-testid={testId}>
      <BannerAdsMarketingMiddleIconWrapper
        data-testid={`${testId}-icon`}
        onClick={() => setIsOpen(false)}
      >
        <Icon name="xmark" variant={Icon.Variants.REGULAR} color={Icon.Colors.BLACK} />
      </BannerAdsMarketingMiddleIconWrapper>
      <Slider
        onChangeSlide={onDotsChange}
        onInnerChangeSlideEnd={onSlideChange}
        currentSlide={slide}
      >
        {slidesAvailable.map((params) => {
          const {
            id,
            title,
            buttonText,
            url,
            bannerBackgroundImage,
            bannerBackgroundColor,
            buttonBackgroundColor,
            buttonTextColor,
            description,
            descriptionColor,
            titleColor,
          } = params;
          return (
            <Banner
              key={id}
              title={title}
              buttonText={buttonText}
              url={url}
              bannerBackgroundColor={bannerBackgroundColor}
              bannerBackgroundImage={bannerBackgroundImage}
              buttonBackgroundColor={buttonBackgroundColor}
              buttonTextColor={buttonTextColor}
              description={description}
              descriptionColor={descriptionColor}
              titleColor={titleColor}
              onClick={handleOnOpenLink}
            />
          );
        })}
      </Slider>
      {isContainsMoreThanOneSlide && (
        <ControlSliderArrows onNext={handleOnNext} onPrev={handleOnPrev} />
      )}
      {isContainsMoreThanOneSlide && <ControlSliderDots activeStep={dotActive - 1} steps={size} />}
    </BannerAdsMarketingMiddleWrapper>
  );
};

export default BannerAdsMarketingMiddle;
