import { defaultTheme, Icon, TypographyOld as Typography } from '@clikaliatech/clikalia-ui';
import React from 'react';
import {
  BannerAdsMarketingSmallWrapper,
  BannerAdsMarketingSmallIconWrapper,
  BannerAdsMarketingSmallContentWrapper,
  ButtonBanner,
} from './BannerAdsMarketingSmall.styled';
import { BannerAdsMarketingSmallProps } from './BannerAdsMarketingSmall.types';

const BannerAdsMarketingSmall = ({
  testId = 'BannerAdsMarketingSmall',
  bannerBackgroundImage,
  bannerBackgroundColor,
  title,
  url,
  titleColor,
  description,
  descriptionColor,
  buttonBackgroundColor,
  buttonTextColor,
  buttonText,
}: BannerAdsMarketingSmallProps) => {
  const [isOpen, setIsOpen] = React.useState(true);

  const handleOnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();

    window.open(url, '_blank');
  };

  if (!isOpen) return null;
  return (
    <BannerAdsMarketingSmallWrapper
      data-testid={testId}
      bannerBackgroundImage={bannerBackgroundImage}
      bannerBackgroundColor={bannerBackgroundColor}
    >
      <BannerAdsMarketingSmallContentWrapper>
        <Typography type={Typography.Types.B1Bold} color={titleColor ?? defaultTheme.palette.white}>
          {title}
        </Typography>
        {description && (
          <Typography
            type={Typography.Types.B2Regular}
            color={descriptionColor ?? defaultTheme.palette.white}
          >
            {description}
          </Typography>
        )}
        {buttonText && (
          <ButtonBanner
            testId={`${testId}-button`}
            size={ButtonBanner.Sizes.Xs}
            variant={ButtonBanner.Variants.Secondary}
            onClick={handleOnClick}
            color={buttonTextColor}
            backgroundColor={buttonBackgroundColor}
          >
            {buttonText}
          </ButtonBanner>
        )}
      </BannerAdsMarketingSmallContentWrapper>

      <BannerAdsMarketingSmallIconWrapper
        data-testid={`${testId}-icon`}
        onClick={() => setIsOpen(false)}
      >
        <Icon name="xmark" variant={Icon.Variants.SHARP} color={Icon.Colors.BLACK} />
      </BannerAdsMarketingSmallIconWrapper>
    </BannerAdsMarketingSmallWrapper>
  );
};

export default BannerAdsMarketingSmall;
