import { SiteConfig, Color } from '../generic/types';

export interface GetSiteConfigurationResponse {
  data: {
    id: number;
    attributes: {
      siteConfig: SiteConfig;
      createdAt: Date;
      updateAt: Date;
      publishedAt: Date;
    };
  };
}

interface FormatsAttributesImageConfig {
  ext: string;
  url: string;
  hash: string;
  mime: string;
  name: string;
  path: string | null;
  size: number;
  width: number;
  height: number;
}

interface AttributesImageConfig {
  name: string;
  alternativeText: string;
  caption: string;
  width: number;
  height: number;
  formats: {
    small: FormatsAttributesImageConfig;
    medium: FormatsAttributesImageConfig;
    thumbnail: FormatsAttributesImageConfig;
  };
  hash: string;
  ext: string;
  mime: string;
  size: number;
  url: string;
  previewUrl: string | null;
  provider: string;
  provider_metadata: string | null;
  createdAt: string;
  updatedAt: string;
}

interface ImageConfigPayload {
  id: number;
  attributes: AttributesImageConfig;
}

export interface ImageConfig {
  data: ImageConfigPayload[] | null;
}

export enum BannerPosition {
  top = 'top',
  middle = 'middle',
  bottom = 'bottom',
}

export enum BannerSize {
  small = 'small',
  middle = 'middle',
  big = 'big',
}
export interface BannerConfig {
  title: string;
  position: BannerPosition;
  size: BannerSize;
  buttonText: string;
  url: string;
  createdAt: string;
  updatedAt: string;
  hexBackgroundColor: Color;
  hexButtonBackgroundColor: Color;
  hexButtonTextColor: Color;
  locale: string;
  active: boolean;
  backgroundImage: ImageConfig;
  description: string;
  hexDescriptionColor: Color;
  hexTitleColor: Color;
}

interface Attributes {
  type: BannerTypes;
  banners: BannerConfig[];
}

export interface BannerConfigResponse {
  id: number;
  attributes: Attributes;
}

export enum BannerTypes {
  Home = 'home',
  TechinalSheetSales = 'technical-sheet-sales',
  TechinalSheetRent = 'technical-sheet-rent',
  PropertiesGridRent = 'properties-grid-rent',
  PropertiesGridSales = 'properties-grid-sales',
  Mortage = 'mortgage',
  Contact = 'contact',
}
export interface BannerConfigurationResponse {
  data: BannerConfigResponse[];
  type: BannerTypes;
}
