import React from 'react';

import Star from '../../svg/Star';

import { ScoreProps } from './Score.types';

const Score: React.FC<ScoreProps> = ({ score }) => {
  return (
    <div>
      {Array(score)
        .fill(Star)
        .map((Component, key) => (
          <Component key={`star-${key}`} />
        ))}
    </div>
  );
};

export default Score;
