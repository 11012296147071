import styled from 'styled-components';
import { HomeHeaderLayoutProps } from './HomeLayout.types';

export const StepIndicatorContainer = styled.div(({ theme }) => ({
  display: 'none',
  position: 'fixed',
  right: theme.spacing(2),
  width: 'auto',
  height: 'min-content',
  bottom: 0,
  top: '50%',
  transform: 'translateY(-50%)',
  [theme.breakpoints.up('lg')]: {
    display: 'block',
  },
}));

export const HeaderLayoutStyled = styled.section<HomeHeaderLayoutProps>(
  ({ theme, transparent }) => ({
    position: 'sticky',
    zIndex: theme.zIndex.navBar,
    transitionProperty: 'background-color',
    transitionDuration: '300ms',
    transitionTimingFunction: 'linear',
    background: transparent ? theme.palette.transparent : theme.palette.white,
    padding: theme.spacing(1.5, 0),
    boxShadow: transparent ? 'none' : `0px 6px 10px 0px ${theme.palette.black}24 `, //24 is alpha

    header: {
      height: 'min-content',
    },

    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3, 0),
    },
  })
);

export const ScrollDownButton = styled.button(({ theme }) => ({
  position: 'absolute',
  bottom: theme.spacing(2),
  left: 'calc(50% - 16px)',
  border: 'none',
  background: 'none',
  cursor: 'pointer',

  [theme.breakpoints.up('sm')]: {
    bottom: theme.spacing(3),
  },

  span: {
    transition: 'font-size 150ms ease-in',
    fontSize: theme.size(1.8),

    ':hover': {
      fontSize: theme.size(2.1),
    },
  },

  [theme.breakpoints.down('lg', 'landscape')]: {
    display: 'none',
  },
}));
