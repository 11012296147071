let placesAutocompleteService: google.maps.places.AutocompleteService;
let placesService: google.maps.places.PlacesService;
let sessionToken: google.maps.places.AutocompleteSessionToken;
let locationMap: google.maps.Map | undefined;

export function initLocationMap(latLng?: google.maps.LatLng | google.maps.LatLngLiteral) {
  const mapContainerElement =
    document.getElementById('locationMap') || document.createElement('div');
  const defaultPosition = { lat: 40.416729, lng: -3.703339 }; // Madrid
  locationMap = new google.maps.Map(mapContainerElement, {
    center: latLng || defaultPosition,
    zoom: latLng ? 15 : 3,
    disableDefaultUI: true,
  });
  return locationMap;
}

export function getLocationMap() {
  return locationMap;
}

export function removeLocationMap() {
  locationMap = undefined;
}

function initAutocompleteService() {
  placesAutocompleteService = new google.maps.places.AutocompleteService();
  return placesAutocompleteService;
}

function initPlacesService() {
  if (locationMap) {
    placesService = new google.maps.places.PlacesService(locationMap);
    return placesService;
  }
}

function getNewSessionToken() {
  sessionToken = new google.maps.places.AutocompleteSessionToken();
  return sessionToken;
}

export function refreshToken() {
  getNewSessionToken();
}

export async function searchAddress(query: string, country?: string, language?: string) {
  if (typeof google !== 'undefined') {
    const client = placesAutocompleteService || initAutocompleteService();
    if (client) {
      const token = sessionToken || getNewSessionToken();

      return await client.getPlacePredictions({
        input: query,
        language: language ?? 'es',
        componentRestrictions: { country: country ?? 'es' },
        types: ['address'],
        sessionToken: token,
      });
    }
  }
  return;
}

export async function getDetails(
  placeId: string,
  cb: (response: google.maps.places.PlaceResult | null) => void,
  language?: string
) {
  if (locationMap) {
    const client = placesService || initPlacesService();
    if (client) {
      client.getDetails(
        {
          placeId,
          sessionToken,
          language: language ?? 'es',
          fields: ['address_components', 'geometry'],
        },
        (response) => cb(response)
      );
    }
  }
}

const googlePlacesService = {
  refreshToken,
  searchAddress,
  getDetails,
  getLocationMap,
  initLocationMap,
  removeLocationMap,
};

export default googlePlacesService;
