import styled from 'styled-components';

export const HomeAcquisitionsSliderWrapper = styled.div(({ theme }) => ({
  width: '100%',
  height: '100%',
  margin: 'auto',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  paddingTop: theme.spacing(8),
  paddingBottom: theme.spacing(8),

  [theme.breakpoints.up('lg')]: {
    maxWidth: '1200px',
    padding: 0,
  },
}));

export const StepsHowWorksWrapper = styled.div({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
});

export const HomeAcquisitionsSliderContentHeader = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  margin: theme.spacing(0, 2, 0, 2),

  h3: {
    ...theme.typography.s1Bold,
  },

  p: {
    textAlign: 'center',
    padding: theme.spacing(1, 0, 0, 0),
  },

  [theme.breakpoints.up('lg')]: {
    margin: theme.spacing(0),

    h3: {
      ...theme.typography.h3,
    },
  },
}));
