import { useContext, useEffect, useState } from 'react';
import { Icon, TypographyOld as Typography } from '@clikaliatech/clikalia-ui';

import { FooterContext } from '../../../../Footer.context';
import { ContactItemsStyled, ContactItemsLinkStyled } from '../../FooterContact.styled';
import { ContactItemsPhoneStyled, ContactExtraInfoStyled } from './FooterPhone.styled';
import { FooterPhoneProps } from './FooterPhone.types';

const FooterPhone: React.FC<FooterPhoneProps> = ({ phone, workingHours }) => {
  const { testId } = useContext(FooterContext);
  // Used this state for prevents hydration issues when phone is according user zone.
  // In this case, the phone may differ in server and client.
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(true);
  }, []);

  if (!phone || !show) return null;

  return (
    <ContactItemsPhoneStyled>
      <ContactItemsStyled>
        <Icon name="phone-flip" variant={Icon.Variants.SHARP} color={Icon.Colors.WHITE} />
        <ContactItemsLinkStyled href={`tel:${phone}`} data-testid={`${testId}Phone`}>
          <Typography type={Typography.Types.B1Bold} color="white">
            {phone}
          </Typography>
        </ContactItemsLinkStyled>
      </ContactItemsStyled>
      {!!workingHours && (
        <ContactExtraInfoStyled>
          <Typography type={Typography.Types.B2Medium} color="white">
            {workingHours}
          </Typography>
        </ContactExtraInfoStyled>
      )}
    </ContactItemsPhoneStyled>
  );
};

FooterPhone.displayName = 'footerPhone';

export default FooterPhone;
