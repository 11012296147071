import { defaultTheme, TypographyOld as Typography } from '@clikaliatech/clikalia-ui';
import { useContext, useMemo } from 'react';
import { useTranslation } from 'next-i18next';
import {
  HomeAcquisitionsCounterCard,
  HomeAcquisitionsCounterCardContainer,
  HomeAcquisitionsCounterImage,
  HomeAcquisitionsCounterImageWrapper,
  HomeAcquisitionsCounterStyled,
  HomeAcquisitionsTitle,
} from './HomeAcquisitionsCounter.styled';
import { homeSections } from 'cross-config/utils/home/homeSections';
import { LocalizationContext } from 'clikalia-web-components';

import { AvailableCountries } from 'cross-config/localization/config';

const esConfig = [true, true, true];
const ptConfig = [true, true, true];
const frConfig = [true, true, true];

const counterConfigByCountry: Record<AvailableCountries, boolean[]> = {
  ES: esConfig,
  PT: ptConfig,
  FR: frConfig,
};

const HomeAcquisitionsCounter: React.FC = () => {
  const { countryCode } = useContext(LocalizationContext);
  const { t } = useTranslation('acquisitions');

  const counterCardsAcquisitions = useMemo(
    () => [
      {
        title: t('acquisitions.whyClikalia.figuresFirstBlock.figure.title'),
        description: t('acquisitions.whyClikalia.figuresFirstBlock.figure.description'),
      },
      {
        title: t('acquisitions.whyClikalia.figuresTwoBlock.figure.title'),
        description: t('acquisitions.whyClikalia.figuresTwoBlock.figure.description'),
      },
      {
        title: t('acquisitions.whyClikalia.figuresThreeBlock.figure.title'),
        description: t('acquisitions.whyClikalia.figuresThreeBlock.figure.description'),
      },
    ],
    [t]
  );

  const filteredCounterByConfig = useMemo(() => {
    if (countryCode) {
      const config: boolean[] = counterConfigByCountry[countryCode];

      return counterCardsAcquisitions.filter(
        (elem) => config[counterCardsAcquisitions.indexOf(elem)]
      );
    }

    return counterCardsAcquisitions;
  }, [counterCardsAcquisitions, countryCode]);

  return (
    <HomeAcquisitionsCounterStyled id={homeSections.Acquisitions.whyClikalia}>
      <HomeAcquisitionsTitle type={Typography.Types.S1Bold}>
        {t('acquisitions.whyClikalia.title')}
      </HomeAcquisitionsTitle>
      <Typography type={Typography.Types.B2Regular}>
        {t('acquisitions.whyClikalia.description')}
      </Typography>
      <HomeAcquisitionsCounterCardContainer>
        {filteredCounterByConfig.map((counterCard) => (
          <HomeAcquisitionsCounterCard key={`cunter-card-${counterCard.title}`}>
            <Typography type={Typography.Types.H2} color={defaultTheme.palette.primary}>
              {counterCard.title}
            </Typography>
            <Typography type={Typography.Types.B2Regular}>{counterCard.description}</Typography>
          </HomeAcquisitionsCounterCard>
        ))}
      </HomeAcquisitionsCounterCardContainer>
      <HomeAcquisitionsCounterImageWrapper>
        <HomeAcquisitionsCounterImage
          src={'https://public.clikaliaes.com/images/adquisition/home/why_clikalia.jpg'}
          alt={'acquisitions-counter-image'}
          sizes="100vw"
        />
      </HomeAcquisitionsCounterImageWrapper>
    </HomeAcquisitionsCounterStyled>
  );
};

export default HomeAcquisitionsCounter;
