//Future improvement children doesn't have displayName for any reason, we dont have the time to fix this.
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useMemo } from 'react';

import {
  FooterContact,
  FooterSections,
  FooterBottom,
  FooterSocial,
  FooterRegions,
} from './components';
import {
  FooterStyled,
  FooterBodyStyled,
  FooterBodyContentStyled,
  FooterContent,
} from './Footer.styled';
import { FooterProps } from './Footer.types';
import { FooterContext } from './Footer.context';

const defaultCover = 'https://public.clikaliaes.com/images/common/background-footer.jpg';

function Footer({
  children,
  testId = 'footerComponent',
  backgroundColor = 'black',
  cover = defaultCover,
}: FooterProps): JSX.Element {
  const contextValue = useMemo(() => ({ testId }), [testId]);

  return (
    <FooterStyled id={testId} data-testid={testId} backgroundColor={backgroundColor} cover={cover}>
      <FooterContent>
        <FooterContext.Provider value={contextValue}>
          <FooterBodyStyled>
            <FooterBodyContentStyled>
              {children &&
                React.Children.map(children, (child) => {
                  if (React.isValidElement(child) && typeof child.type === 'function') {
                    if (
                      (child.type as any).displayName === 'footerContact' ||
                      (child.type as any).displayName === 'footerLogo' ||
                      (child.type as any).displayName === 'footerSections'
                    )
                      return child;
                  }
                  return null;
                })}
            </FooterBodyContentStyled>
            {children &&
              React.Children.map(children, (child) => {
                if (React.isValidElement(child) && typeof child.type === 'function') {
                  if (
                    (child.type as any).displayName === 'footerRegions' ||
                    (child.type as any).displayName === 'footerBottom' ||
                    (child.type as any).displayName === 'footerSocial'
                  )
                    return child;
                }
                return null;
              })}
          </FooterBodyStyled>
        </FooterContext.Provider>
      </FooterContent>
    </FooterStyled>
  );
}

Footer.Contact = FooterContact;

Footer.Social = FooterSocial;

Footer.Sections = FooterSections;

Footer.Bottom = FooterBottom;

Footer.Regions = FooterRegions;

export default Footer;
