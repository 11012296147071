import { Button } from '@clikaliatech/clikalia-ui';
import styled from 'styled-components';
import {
  BannerAdsMarketingSmallWrapperProps,
  ButtonBannerProps,
} from './BannerAdsMarketingSmall.types';

export const BannerAdsMarketingSmallWrapper = styled.section<BannerAdsMarketingSmallWrapperProps>(
  ({ bannerBackgroundImage, bannerBackgroundColor }) => ({
    display: 'flex',
    justifyContent: 'center',
    height: '40px',
    position: 'relative',
    background: `url(${bannerBackgroundImage}) no-repeat 0, 0`,
    backgroundSize: 'cover',
    ...(!bannerBackgroundImage && {
      '&:after': {
        position: 'absolute',
        content: "' '",
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 0,
        backgroundColor: bannerBackgroundColor,
      },
    }),
  })
);
export const BannerAdsMarketingSmallContentWrapper = styled.div(({ theme }) => ({
  width: '720px',
  height: '100%',
  padding: theme.spacing(0, 4, 0, 4),
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  '&>*': {
    zIndex: 1,
  },
  '& p:nth-child(2)': {
    display: 'none',
  },
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(0),
    '& p:nth-child(2)': {
      display: 'block',
    },
  },
}));

export const BannerAdsMarketingSmallIconWrapper = styled.div(({ theme }) => ({
  height: 20,
  position: 'absolute',
  right: 10,
  top: 0,
  zIndex: theme.zIndex.navBar,
  cursor: 'pointer',
  '> span': {
    zIndex: 1,
    width: '100%',
    height: '100%',
    display: 'grid',
    alignContent: 'center',
  },
}));

export const ButtonBanner = styled(Button)<ButtonBannerProps>(({ color, backgroundColor }) => ({
  backgroundColor,
  color,
  textDecoration: 'inherit',
  borderRadius: '6px',
  borderColor: backgroundColor,
}));
