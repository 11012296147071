import styled from 'styled-components';

export const FooterContactStyled = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
  width: '100%',

  gridArea: 'contact',

  [theme.breakpoints.up('md')]: {
    width: 'fit-content',
    maxWidth: 320,
  },
}));

export const ContactItemsStyled = styled.div(({ theme }) => ({
  display: 'grid',
  grid: 'auto / repeat(auto-fit, minmax(0, auto))',
  alignItems: 'baseline',
  justifyContent: 'center',
  textAlign: 'center',
  color: theme.palette.white,
  gap: theme.spacing(1),
  p: {
    whiteSpace: 'pre-wrap',
    margin: 0,
  },

  [theme.breakpoints.up('md')]: {
    justifyContent: 'flex-start',
    textAlign: 'left',
  },
}));

export const ContactItemsLinkStyled = styled.a<{ isRemoveBoldStyleInDesktopDevices?: boolean }>(
  ({ theme, isRemoveBoldStyleInDesktopDevices }) => ({
    color: theme.palette.white,
    textDecoration: 'none',

    [theme.breakpoints.up('md')]: {
      p: {
        ...(isRemoveBoldStyleInDesktopDevices ? theme.typography.b1Regular : {}),
      },
    },
  })
);
