/* eslint-disable @next/next/no-img-element */
import { useContext, useMemo } from 'react';
import { useTranslation } from 'next-i18next';
import { Cover, LocalizationContext } from 'clikalia-web-components';
import { HomeCoverBackgroundImage, HomeCoverNewsWrapper, HomeCoverStyle } from './HomeCover.styled';
import { HomeCoverProp, Section } from './HomeCover.types';
import { useRouter } from 'next/router';
import { getHomeCoverDataByCountry, pressLogosByCountry } from 'cross-config/utils/home/coverData';
import { TFuncKey } from 'i18next';

const HomeCover: React.FC<HomeCoverProp> = ({ section, backgroundImage, onClick, testId }) => {
  const { locale, defaultLocale } = useRouter();
  const { t } = useTranslation(['common', 'acquisitions']);

  const { countryCode } = useContext(LocalizationContext);

  const coverData = useMemo(() => {
    const coverDataByCountry = getHomeCoverDataByCountry({
      country: countryCode,
      locale,
      defaultLocale,
    });

    return coverDataByCountry.map((data) => ({
      index: data.index,
      title: t(data.title as TFuncKey) as string,
      sectionName: t(data.sectionName as TFuncKey) as string,
      value: data.value as Section,
      ctaLabel: t(data.ctaLabel as TFuncKey) as string,
      informationList: data.informationList.map(
        (information) => t(information as TFuncKey) as string
      ),
      onSelect: () =>
        data.value === 'acquisitions' ? undefined : (window.location.href = data.selectLink),
      ctaOnClick:
        data.value === 'acquisitions' ? onClick : () => (window.location.href = data.ctaClickLink),
    }));
  }, [t, countryCode, onClick, locale, defaultLocale]);

  const onSelectSection = (value: Section) => {
    const section = coverData.find((element) => element.value === value);
    section?.onSelect();
  };

  const activeSectionData = useMemo(() => {
    const data = coverData.find((element) => element.value === section);
    return data ?? coverData[0];
  }, [section, coverData]);

  return (
    <HomeCoverStyle>
      <HomeCoverBackgroundImage src={backgroundImage} alt="Home cover background" sizes="100vw" />
      <Cover
        testId={testId}
        activeSection={activeSectionData}
        sections={coverData}
        onSelectSection={onSelectSection}
      />
      {pressLogosByCountry[countryCode] && (
        <HomeCoverNewsWrapper>
          <img
            src={`${process.env.https://public.clikaliaes.com/images}/common/forbes.png`}
            alt="newspaper logo Forbes"
            height={18}
            width={66}
          />
          <img
            src={`${process.env.https://public.clikaliaes.com/images}/common/milenio.png`}
            alt="newspaper logo Milenio"
            height={18}
            width={100}
          />
          <img
            src={`${process.env.https://public.clikaliaes.com/images}/common/eleconomista.png`}
            alt="newspaper logo El Economista"
            height={18}
            width={187}
          />
          <img
            src={`${process.env.https://public.clikaliaes.com/images}/common/expansion.png`}
            alt="newspapept logo Expansión"
            height={17}
            width={87}
          />
        </HomeCoverNewsWrapper>
      )}
    </HomeCoverStyle>
  );
};

export default HomeCover;
