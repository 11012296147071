interface Location {
  number: string;
  street: string;
  city: string;
  province: string;
  postalCode: string;
  country: string;
}

export function getAddressComponents(component: google.maps.GeocoderAddressComponent[]): Location {
  const getComponent = (type: string) => component.find((comp) => comp.types.includes(type));

  return {
    number: getComponent('street_number')?.long_name || '',
    street: getComponent('route')?.long_name || '',
    city: getComponent('locality')?.long_name || '',
    province: getComponent('administrative_area_level_2')?.long_name || '',
    postalCode: getComponent('postal_code')?.long_name || '',
    country: getComponent('country')?.short_name.toLowerCase() || '',
  };
}
