import React from 'react';
import { Icon } from '@clikaliatech/clikalia-ui';

import { ControlButton, ControlSliderArrowsWrapper } from './ControlSliderArrows.styled';
import { ControlSliderArrowsProps } from './ControlSliderArrows.types';

const ControlSliderArrows: React.FC<ControlSliderArrowsProps> = ({
  testId = 'arrowsComponent',
  onPrev,
  onNext,
}) => {
  return (
    <ControlSliderArrowsWrapper data-testid={testId}>
      <ControlButton
        onClick={onPrev}
        aria-label={`${testId}-previousButton`}
        data-testid={`${testId}-previousButton`}
      >
        <Icon
          name="chevron-left"
          variant={Icon.Variants.SHARP}
          color={Icon.Colors.PRIMARY}
          testId={`${testId}-previousIcon`}
        />
      </ControlButton>
      <ControlButton
        onClick={onNext}
        className="right-control"
        aria-label={`${testId}-nextButton`}
        data-testid={`${testId}-nextButton`}
      >
        <Icon
          name="chevron-right"
          variant={Icon.Variants.SHARP}
          color={Icon.Colors.PRIMARY}
          testId={`${testId}-nextIcon`}
        />
      </ControlButton>
    </ControlSliderArrowsWrapper>
  );
};

export default ControlSliderArrows;
