import { useContext, useEffect, useState } from 'react';
import { Icon } from '@clikaliatech/clikalia-ui';
import { HeaderContext } from '../../Header.context';
import { HeaderContactPhoneStyled, HeaderContactStyled } from './HeaderContact.styled';
import { HeaderContactProps } from './HeaderContact.types';

const HeaderContact: React.FC<HeaderContactProps> = ({ phone }) => {
  const { transparent, newDesign } = useContext(HeaderContext);
  // Used this state for prevents hydration issues when phone is according user zone.
  // In this case, the phone may difer in server and client.
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(true);
  }, []);

  if (!show) {
    return null;
  }

  return (
    <HeaderContactStyled
      testId="contactPhoneComponent"
      data-testid="contactPhoneComponent"
      href={`tel:${phone}`}
      transparent={transparent && !newDesign}
      aria-label="contact-phone"
    >
      <HeaderContactPhoneStyled transparent={transparent && !newDesign}>
        {phone}
      </HeaderContactPhoneStyled>
      <Icon
        name="phone-flip"
        variant={Icon.Variants.REGULAR}
        color={transparent && !newDesign ? Icon.Colors.WHITE : Icon.Colors.PRIMARY}
      />
    </HeaderContactStyled>
  );
};

export default HeaderContact;
