import { StepIndicator } from '@clikaliatech/clikalia-ui';

import { ControlSliderDotsWrapper } from './ControlSliderDots.styled';
import { ControlSliderDotsProps } from './ControlSliderDots.types';

const ControlSliderDots: React.FC<ControlSliderDotsProps> = ({
  testId = 'dotsComponent',
  activeStep,
  steps,
}) => {
  return (
    <ControlSliderDotsWrapper data-testid={testId}>
      <StepIndicator activeStep={activeStep} steps={steps} testId={`${testId}-stepIndicator`} />
    </ControlSliderDotsWrapper>
  );
};

export default ControlSliderDots;
