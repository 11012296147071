import React from 'react';
import { useTranslation } from 'next-i18next';
import { TypographyOld as Typography } from '@clikaliatech/clikalia-ui';
import { StepsHowWorks } from 'clikalia-web-components';

import { homeSections } from 'cross-config/utils/home/homeSections';

import {
  HomeAcquisitionsSliderWrapper,
  HomeAcquisitionsSliderContentHeader,
  StepsHowWorksWrapper,
} from './HomeAcquisitionsSlider.styled';

const HomeAcquisitionsSlider: React.FC = () => {
  const { t } = useTranslation('acquisitions');

  const stepsHowWorks = [
    {
      title: t('acquisitions.howItWorks.stepOne.title'),
      description: t('acquisitions.howItWorks.stepOne.description'),
      pictureActive:
        'https://public.clikaliaes.com/images/adquisition/home/acquisitions-1-active.svg',
      pictureInactive:
        'https://public.clikaliaes.com/images/adquisition/home/acquisitions-1-inactive.svg',
    },
    {
      title: t('acquisitions.howItWorks.stepTwo.title'),
      description: t('acquisitions.howItWorks.stepTwo.description'),
      pictureActive:
        'https://public.clikaliaes.com/images/adquisition/home/acquisitions-2-active.svg',
      pictureInactive:
        'https://public.clikaliaes.com/images/adquisition/home/acquisitions-2-inactive.svg',
    },
    {
      title: t('acquisitions.howItWorks.stepThree.title'),
      description: t('acquisitions.howItWorks.stepThree.description'),
      pictureActive:
        'https://public.clikaliaes.com/images/adquisition/home/acquisitions-3-active.svg',
      pictureInactive:
        'https://public.clikaliaes.com/images/adquisition/home/acquisitions-3-inactive.svg',
    },
  ];

  return (
    <HomeAcquisitionsSliderWrapper id={homeSections.Acquisitions.howSellWorks}>
      <HomeAcquisitionsSliderContentHeader>
        <Typography type={Typography.Types.H3}>{t('acquisitions.howItWorks.title')}</Typography>
        <Typography type={Typography.Types.B2Regular}>
          {t('acquisitions.howItWorks.description')}
        </Typography>
      </HomeAcquisitionsSliderContentHeader>
      <StepsHowWorksWrapper>
        <StepsHowWorks dataSource={stepsHowWorks} defaultValue={stepsHowWorks[0].title} />
      </StepsHowWorksWrapper>
    </HomeAcquisitionsSliderWrapper>
  );
};

export default HomeAcquisitionsSlider;
