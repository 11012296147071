import styled from 'styled-components';

export const DescriptionPanelWrapper = styled.div(({ theme: { breakpoints, spacing } }) => ({
  display: 'none',
  [breakpoints.up('sm')]: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'start',
    padding: spacing(2, 3, 0, 2),
    whiteSpace: 'pre-line',
  },
}));
