import styled from 'styled-components';

export const FooterAbstractStyled = styled.div(({ theme }) => ({
  color: theme.palette.white,
  fontWeight: 300,
  fontSize: theme.size(1.4),
  lineHeight: theme.size(2.4),
  fontStyle: 'italic',
  display: 'none',
  paddingBottom: theme.size(2),
  whiteSpace: 'pre-wrap',

  [theme.breakpoints.up('md')]: {
    display: 'block',
  },
}));
