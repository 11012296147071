import { FC, useContext } from 'react';
import { useRouter } from 'next/router';
import { COOKIES } from 'cross-config/generic/cookies';

import {
  Header,
  HeaderContact,
  HeaderLogo,
  HeaderLanguage,
  GeneralContext,
} from 'clikalia-web-components';
import { useCookie } from 'clikalia-hooks';

import { HomeHeaderProps } from './HomeHeader.types';
import {
  I18nLanguageMapToFriendlyLanguage,
  I18nLanguageMapToCountry,
} from 'cross-config/localization/config';
import { handleAnchorEvents } from 'cross-config/utils/handleAnchorEvents';

const HomeHeader: FC<HomeHeaderProps> = ({ transparent = false, pathname = '/' }) => {
  const { header } = useContext(GeneralContext);
  const router = useRouter();
  const { setCookie } = useCookie();

  const handleChangeLanguage = (value: string) => {
    const { pathname, asPath, query } = router;

    setCookie({
      key: COOKIES.NEXT_LOCALE,
      value: value,
    });

    router.replace({ pathname, query }, asPath, { locale: value });
  };

  return (
    <Header position={'relative'} transparent={transparent}>
      <Header.LeftContent
        onClick={handleAnchorEvents}
        menuOptions={header.sections}
        contactOptions={header.contact}
      >
        <HeaderLogo pathname={pathname} />
        <HeaderLanguage
          language={I18nLanguageMapToFriendlyLanguage[router.locale as string] ?? 'ES'}
          onSelect={handleChangeLanguage}
          country={I18nLanguageMapToCountry[router.locale as string]}
        />
      </Header.LeftContent>
      <Header.RightContent>
        <HeaderContact phone={header.contact.phone} />
      </Header.RightContent>
    </Header>
  );
};

export default HomeHeader;
