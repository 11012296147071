import { AvailableCountries } from '../../localization/config';
import { getLocationOriginSSR } from '../getLocationOriginSSR';
import {
  EShomeVerticalsRouting,
  FRhomeVerticalsRouting,
  PThomeVerticalsRouting,
} from '../../localization/routes';
import { getUrlLocale } from '../../localization/utils';

type Verticals = 'acquisitions' | 'sales' | 'rent' | 'mortgage';
type Routes = { base: string; link?: string };

const esRouting: Record<Verticals, Routes> = {
  acquisitions: { base: EShomeVerticalsRouting.acquisitions, link: '/formulario' },
  sales: { base: EShomeVerticalsRouting.sales, link: '/donde-comprar/provincias' },
  rent: { base: EShomeVerticalsRouting.rent, link: '/donde-alquilar/provincias' },
  mortgage: { base: EShomeVerticalsRouting.mortgage, link: '/calcula-tu-hipoteca' },
};

const frRouting: Record<Verticals, Routes> = {
  acquisitions: { base: FRhomeVerticalsRouting.acquisitions, link: '/formulaire' },
  sales: { base: FRhomeVerticalsRouting.sales, link: '/paris' },
  rent: { base: FRhomeVerticalsRouting.rent, link: '/ou-acheter/province' },
  mortgage: { base: FRhomeVerticalsRouting.mortgage, link: '/calculez-votre-hypotheque' },
};

const ptRouting: Record<Verticals, Routes> = {
  acquisitions: { base: PThomeVerticalsRouting.acquisitions, link: '/formulario' },
  sales: { base: PThomeVerticalsRouting.sales, link: '/lisboa' },
  rent: { base: PThomeVerticalsRouting.rent, link: '/onde-alugar/provincias' },
  mortgage: { base: PThomeVerticalsRouting.mortgage, link: '/calcula-a-tua-hipoteca' },
};

export const routes = {
  ES: esRouting,
  FR: frRouting,
  PT: ptRouting,
};

const verticalsByCountry: Record<AvailableCountries, Verticals[]> = {
  ES: ['acquisitions', 'sales', 'rent', 'mortgage'],
  FR: ['acquisitions', 'sales'],
  PT: ['acquisitions', 'sales', 'mortgage'],
};

interface UrlBuilderSelectProps {
  vertical: string;
  locale?: string;
  defaultLocale?: string;
  link?: string;
}

const urlBuilderSelect = ({ vertical, locale, defaultLocale, link }: UrlBuilderSelectProps) => {
  const urlBase = getLocationOriginSSR();
  const urlSlug = getUrlLocale({ locale, defaultLocale });
  const urlWithLanguage = `${urlBase}${vertical}${urlSlug}${link ?? ''}`;

  return urlWithLanguage;
};

interface getHomeCoverDataByCountryProps {
  country: AvailableCountries;
  locale?: string;
  defaultLocale?: string;
}

export const getHomeCoverDataByCountry = ({
  country,
  locale,
  defaultLocale,
}: getHomeCoverDataByCountryProps) => {
  const verticals = verticalsByCountry[country];

  return verticals.map((vertical, index) => ({
    index: index + 1,
    value: vertical,
    title: `common:home.verticals.${vertical}.title`,
    sectionName: `common:home.verticals.${vertical}.label`,
    ctaLabel: `common:home.verticals.${vertical}.ctaLabel`,
    informationList: [
      `common:home.verticals.${vertical}.descriptionOne`,
      `common:home.verticals.${vertical}.descriptionTwo`,
      `common:home.verticals.${vertical}.descriptionThree`,
    ],
    selectLink: urlBuilderSelect({
      vertical: routes[country][vertical].base,
      locale,
      defaultLocale,
    }),
    ctaClickLink: urlBuilderSelect({
      vertical: routes[country][vertical].base,
      locale,
      defaultLocale,
      link: routes[country][vertical].link,
    }),
    ctaClickLinkForPush: routes[country][vertical].link || '#',
  }));
};

export const pressLogosByCountry: Record<AvailableCountries, boolean> = {
  ES: true,
  FR: true,
  PT: false,
};
