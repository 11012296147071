import styled from 'styled-components';

const ListStyled = styled.ul(({ theme: { spacing } }) => ({
  display: 'flex',
  flexDirection: 'column',
  margin: spacing(1, 0, 0, 0),
  width: '100%',
  maxWidth: '780px',
}));

const ListItem = styled.li(({ theme }) => ({
  color: theme.palette.white,
}));

export { ListStyled, ListItem };
