import styled from 'styled-components';

export const ControlSliderArrowsWrapper = styled.div({
  display: 'flex',
  width: '100%',
  position: 'absolute',
  height: '100%',
  alignItems: 'center',

  /* smartphones, touchscreens */
  /* Source: https://ferie.medium.com/detect-a-touch-device-with-only-css-9f8e30fa1134 */
  '@media (hover: none) and (pointer: coarse)': {
    display: 'none',
  },
});

export const ControlButton = styled.button(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'absolute',
  left: 0,
  width: theme.spacing(8),
  height: theme.spacing(8),
  border: 'none',
  background: 'none',
  cursor: 'pointer',
  padding: 0,
  margin: 0,
  '&.right-control': {
    left: 'unset',
    right: 0,
  },
  '> span': {
    zIndex: 1,
    width: '100%',
    height: '100%',
    display: 'grid',
    alignContent: 'center',
    fontSize: theme.spacing(4),
  },
}));
