import { useRef, useEffect, useCallback, FC, Children } from 'react';

import { useDebounceFn } from '@clikaliatech/clikalia-ui';

import {
  SectionsSliderContainer,
  SectionsSliderFooter,
  SectionsSliderHeader,
  SectionsSliderItem,
} from './SectionsSlider.styled';

import { SectionsSliderProps } from './SectionsSlider.types';

const SectionsSlider: FC<SectionsSliderProps> = ({
  children,
  currentSlide,
  onInnerChangeSlideEnd,
  onChangeSlide,
  width,
  height,
  testId = 'sectionsSliderComponent',
}) => {
  const sectionsSliderContainerRef = useRef<HTMLDivElement>(null);
  const lastCurrentSlide = useRef(currentSlide);

  const handleOnInnerChangeSlideEnd = (slide: number): void => {
    if (slide !== currentSlide) onInnerChangeSlideEnd?.(slide);
  };

  const { handleDebounce } = useDebounceFn(handleOnInnerChangeSlideEnd, 200);

  const handleOnScroll: React.UIEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      const elem = event.target as HTMLDivElement;
      const scrollTop = elem.scrollTop;
      const currentSlideNumber = Math.round(scrollTop / elem.offsetHeight + 1);
      handleDebounce(currentSlideNumber);
      if (currentSlideNumber !== lastCurrentSlide.current) {
        lastCurrentSlide.current = currentSlideNumber;
        onChangeSlide?.(currentSlideNumber);
      }
    },
    [lastCurrentSlide, onChangeSlide, handleDebounce]
  );

  useEffect(() => {
    const sectionsSliderElem = sectionsSliderContainerRef.current;
    if (sectionsSliderElem && currentSlide !== undefined) {
      sectionsSliderElem.scrollTop = sectionsSliderElem.offsetHeight * (currentSlide - 1);
    }
  }, [currentSlide]);

  return (
    <SectionsSliderContainer
      ref={sectionsSliderContainerRef}
      onScroll={handleOnScroll}
      width={width}
      height={height}
      data-testid={testId}
    >
      {Children.toArray(children).map((child, index) => {
        // First item for Header
        if (index === 0) {
          return <SectionsSliderHeader key={`${testId}-${index}`}>{child}</SectionsSliderHeader>;
        }

        // Last item for StepIndicator
        if (index === Children.count(children) - 1) {
          return <div key={`${testId}-${index}`}>{child}</div>;
        }

        if (index === Children.count(children) - 2) {
          return <SectionsSliderFooter key={`${testId}-${index}`}>{child}</SectionsSliderFooter>;
        }

        return <SectionsSliderItem key={`${testId}-${index}`}>{child}</SectionsSliderItem>;
      })}
    </SectionsSliderContainer>
  );
};

export default SectionsSlider;
