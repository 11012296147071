import React, { useState } from 'react';

import ViewHowWork from '../ViewHowWork';

import { ViewHowWorkStepsWrapper } from './ViewHowWorkSteps.styled';
import { ViewHowWorkStepsProps } from './ViewHowWorkSteps.types';

const ViewHowWorkSteps: React.FC<ViewHowWorkStepsProps> = ({
  testId = 'viewHowWorkStepsComponent',
  dataSource = [],
  defaultValue = '',
}) => {
  const [titleActive, setTitleActive] = useState(defaultValue);

  const handleOnMouseEnter = (title: string): void => {
    setTitleActive(title);
  };

  const handleOnMouseLeave = (): void => {
    setTitleActive('');
  };

  return (
    <ViewHowWorkStepsWrapper data-testid={testId}>
      {dataSource.map((params) => {
        const { title, description, pictureActive, pictureInactive } = params;

        const isActive = titleActive === title;

        return (
          <div
            key={title}
            title={title}
            onMouseEnter={() => handleOnMouseEnter(title)}
            onMouseLeave={handleOnMouseLeave}
          >
            <ViewHowWork
              title={title}
              description={description}
              picture={isActive ? pictureActive : pictureInactive}
              active={isActive}
              testId={testId}
              alt={title}
            />
          </div>
        );
      })}
    </ViewHowWorkStepsWrapper>
  );
};

export default ViewHowWorkSteps;
