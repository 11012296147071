import React from 'react';

import { RestrictedStyling } from '../../../types/commons';

import ViewHowWorkSteps from './components/ViewHowWorkSteps';
import ViewHowWorkSliders from './components/ViewHowWorkSliders';

import { ViewHowWorkSlidersWrapper, ViewHowWorkStepsWrapper } from './StepsHowWorks.styled';
import { StepsHowWorksProps } from './StepsHowWorks.types';

const StepsHowWorks: React.FC<RestrictedStyling<StepsHowWorksProps>> = ({
  testId = 'stepsHowWorksComponent',
  dataSource,
  defaultValue,
}) => {
  return (
    <div data-testid={testId}>
      <ViewHowWorkStepsWrapper>
        <ViewHowWorkSteps dataSource={dataSource} defaultValue={defaultValue} />
      </ViewHowWorkStepsWrapper>
      <ViewHowWorkSlidersWrapper>
        <ViewHowWorkSliders dataSource={dataSource} />
      </ViewHowWorkSlidersWrapper>
    </div>
  );
};

export default StepsHowWorks;
