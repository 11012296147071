import styled from 'styled-components';

const Wrapper = styled.div(({ theme: { breakpoints, spacing } }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  justifyContent: 'start',
  width: '100%',
  padding: spacing(1, 2, 0),
  [breakpoints.up('sm')]: {
    width: '100%',
    textAlign: 'end',
  },
}));

const DescriptionWrapper = styled.div(({ theme: { palette, breakpoints, spacing } }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  justifyContent: 'start',
  width: '100%',
  overflow: 'auto',
  whiteSpace: 'pre-line',
  margin: spacing(1, 0, 0),
  [breakpoints.up('sm')]: {
    display: 'none',
  },
}));

export { Wrapper, DescriptionWrapper };
