import React from 'react';

import { RegionWrapper, FooterRegionsWrapper, ItemFlagStyled } from './FooterRegions.styled';
import { FooterRegionsProps, RegionProps } from './FooterRegions.types';

const countriesEmojies: Record<string, string> = {
  france: '🇫🇷',
  spain: '🇪🇸',
  portugal: '🇵🇹',
  mexico: '🇲🇽',
  italy: '🇮🇹',
};

const Region: React.FC<RegionProps> = ({ name, url, label, testId = 'regionComponent' }) => {
  return (
    <RegionWrapper href={url} target="_blank" rel="noreferrer" data-testid={testId}>
      <ItemFlagStyled>{countriesEmojies[name]}</ItemFlagStyled>
      {label}
    </RegionWrapper>
  );
};

const FooterRegions: React.FC<FooterRegionsProps> & {
  Region: typeof Region;
} = ({ children }) => {
  return <FooterRegionsWrapper data-testid="footerRegions">{children}</FooterRegionsWrapper>;
};

FooterRegions.displayName = 'footerRegions';

FooterRegions.Region = Region;

export default FooterRegions;
