const Star = () => {
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.0326 5.59177C15.9837 5.4476 15.8936 5.32093 15.7735 5.22739C15.6533 5.13384 15.5085 5.07752 15.3567 5.06537L10.9174 4.71262L8.99634 0.460182C8.93516 0.32322 8.83564 0.20689 8.70981 0.125233C8.58398 0.0435766 8.4372 8.24472e-05 8.2872 1.17099e-07C8.13719 -8.2213e-05 7.99037 0.0432508 7.86445 0.124769C7.73853 0.206288 7.63889 0.322508 7.57756 0.459403L5.65652 4.71262L1.2172 5.06537C1.06804 5.07719 0.92545 5.13172 0.806479 5.22246C0.687508 5.31319 0.597196 5.43628 0.546347 5.57699C0.495499 5.71771 0.486267 5.8701 0.519756 6.01592C0.553246 6.16175 0.628038 6.29484 0.735186 6.39927L4.01582 9.59736L2.85557 14.6215C2.82034 14.7736 2.83163 14.9327 2.88797 15.0783C2.94432 15.2239 3.04312 15.3492 3.17156 15.4379C3.3 15.5266 3.45214 15.5747 3.60824 15.5759C3.76434 15.5771 3.91719 15.5313 4.04697 15.4446L8.28695 12.6179L12.5269 15.4446C12.6596 15.5326 12.816 15.578 12.9752 15.5745C13.1343 15.571 13.2886 15.5188 13.4173 15.425C13.5459 15.3312 13.6428 15.2003 13.6948 15.0498C13.7468 14.8993 13.7515 14.7366 13.7082 14.5833L12.284 9.5997L15.8161 6.42107C16.0474 6.21239 16.1323 5.88689 16.0326 5.59177Z"
        fill="#FFD600"
      />
    </svg>
  );
};

export default Star;
