import { Fragment } from 'react';
import { ImageSlider, StepIndicator, defaultTheme } from '@clikaliatech/clikalia-ui';
import Content from './components/Content';
import useControlSlider from '../../../hooks/useControlSlider';
import {
  CoverWrapper,
  SectionNamesStyled,
  SectionNameElement,
  CarrouselWrapper,
  CarrouselElement,
  StepIndicatorsContainer,
  DividerStyled,
} from './Cover.styled';
import { CoverProps } from './Cover.types';

const Cover = <T,>({
  activeSection,
  sections,
  testId = 'coverComponent',
  onSelectSection,
}: CoverProps<T>): JSX.Element => {
  const { onSlideChange, onDotsChange, slide, dotActive } = useControlSlider({
    sliders: sections.length,
    initialState: activeSection.index,
    initialDot: activeSection.index,
  });

  const onChangeSlide = (newSlide: number) => {
    onSlideChange(newSlide);
  };

  return (
    <>
      <CoverWrapper id={`${testId}Desktop`} data-testid={`${testId}Desktop`}>
        <Content
          title={activeSection.title}
          ctaLabel={activeSection.ctaLabel}
          informationList={activeSection.informationList}
          onClick={activeSection.ctaOnClick}
          testId={`cover-cta-${activeSection.sectionName}`}
        />
        <SectionNamesStyled>
          {sections.map((element, index) => (
            <Fragment key={`sectionDesktop-${element.value}`}>
              <div>
                <SectionNameElement
                  id={`section-${element.sectionName}`}
                  data-testid={`section-${element.sectionName}`}
                  isActive={element.value === activeSection.value}
                  onClick={() => onSelectSection(element.value)}
                >
                  {element.sectionName}
                </SectionNameElement>
              </div>
              {index !== sections.length - 1 && <DividerStyled key={`${element.value}-divider`} />}
            </Fragment>
          ))}
        </SectionNamesStyled>
      </CoverWrapper>
      <CarrouselWrapper id={`${testId}Mobile`} data-testid={`${testId}Mobile`}>
        <ImageSlider
          currentSlide={slide}
          onChangeSlide={onDotsChange}
          onInnerChangeSlideEnd={onChangeSlide}
          testId={`${testId}-slider`}
        >
          {sections.map((element) => (
            <CarrouselElement key={`sectionMobile-${element.value}`}>
              <Content
                title={element.title}
                ctaLabel={element.ctaLabel}
                informationList={element.informationList}
                onClick={element.ctaOnClick}
                testId={`cover-cta-${element.value}`}
              />
            </CarrouselElement>
          ))}
        </ImageSlider>
        <StepIndicatorsContainer>
          <StepIndicator
            steps={sections.length}
            activeStep={dotActive - 1}
            inactiveColor={defaultTheme.palette.gray30}
            testId={`${testId}-stepIndicator`}
          />
        </StepIndicatorsContainer>
      </CarrouselWrapper>
    </>
  );
};

export default Cover;
