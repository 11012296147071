import styled from 'styled-components';

export const ReviewCardWrapper = styled.section(({ theme: { spacing } }) => ({
  display: 'flex',
  flexDirection: 'column',
  margin: spacing(0, 0.5),
}));

export const ReviewContainer = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  background: theme.palette.white,
  borderRadius: '10px',
  boxShadow: `0px 1px 8px ${theme.palette.black30}`,
  padding: theme.spacing(4, 2.5),
  position: 'relative',
  minHeight: 280,
  width: '100%',
  textAlign: 'center',
}));

export const ScoreContainer = styled.div({
  display: 'flex',
  justifyContent: 'center',
});
