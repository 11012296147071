import styled from 'styled-components';

export const FooterWrapper = styled.div(({ theme }) => ({
  height: '100%',

  footer: {
    height: '100%',
    paddingTop: theme.spacing(10),
  },
}));
