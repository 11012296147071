import { useContext } from 'react';

import { FooterContext } from '../../Footer.context';
import { FooterCopyright, FooterLegalLink } from './components';
import { FooterBottomStyled } from './FooterBottom.styled';
import { FooterBottomProps } from './FooterBottom.types';

function FooterBottom({ children }: FooterBottomProps) {
  const { testId } = useContext(FooterContext);

  if (!children) return null;

  return (
    <FooterBottomStyled data-testid={`${testId}BottomComponent`}>{children}</FooterBottomStyled>
  );
}

FooterBottom.displayName = 'footerBottom';

FooterBottom.Copyright = FooterCopyright;
FooterBottom.LegalLink = FooterLegalLink;

export default FooterBottom;
