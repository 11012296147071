import { BannersContext } from 'clikalia-web-components';
import { BannerSize } from 'cross-config/models/clikaliaCms.interface';
import { extractUrlOfImage } from 'cross-config/utils/strapi';
import React, { useContext, useMemo } from 'react';

const HomeAcquisitionsCMSBanner = () => {
  const { selectBanner, renderBannerComponentBySize } = useContext(BannersContext);

  const ComponentToRender = renderBannerComponentBySize(BannerSize.small);

  const dataSource = useMemo(() => {
    const banners = selectBanner('top');

    return banners.map((banner) => ({
      id: String(banner.title).toLowerCase(),
      title: banner.title,
      active: banner.active,
      buttonText: banner.buttonText,
      url: banner.url,
      bannerBackgroundImage: extractUrlOfImage(banner.backgroundImage),
      bannerBackgroundColor: banner.hexBackgroundColor,
      buttonBackgroundColor: banner.hexButtonBackgroundColor,
      buttonTextColor: banner.hexButtonTextColor,
      description: banner.description,
      descriptionColor: banner.hexDescriptionColor,
      titleColor: banner.hexTitleColor,
    }));
  }, [selectBanner]);

  if (!dataSource.length) {
    return null;
  }

  return <ComponentToRender {...dataSource[0]} />;
};

export default React.memo(HomeAcquisitionsCMSBanner);
