import styled from 'styled-components';

export const ContactItemsPhoneStyled = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'center',

  [theme.breakpoints.up('md')]: {
    textAlign: 'left',
  },
}));

export const ContactExtraInfoStyled = styled.div(({ theme }) => ({
  p: {
    margin: 0,
  },

  [theme.breakpoints.up('md')]: {
    marginLeft: theme.spacing(3),
  },
}));
