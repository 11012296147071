import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';

import { SiteConfig } from 'cross-config/generic/types';
import { convertStrapiURLWithLocale } from 'cross-config/utils/handleAnchorEvents';
import {
  HeaderConfig,
  GeneralContextProps,
  GeneralContextProviderProps,
} from './GeneralContext.types';
import { useTranslation } from 'next-i18next';
import { TFuncKey } from 'i18next';
import { useZones } from 'clikalia-hooks';

import { LocalizationContext } from '../LocalizationContext';

const defaultSiteConfig: SiteConfig = {
  email: 'hola@clikalia.com',
  footer: {
    legal: [{ name: '', url: '' }],
    sections: [{ name: 'venta', url: '/casas-en-venta' }],
  },
  header: [
    {
      url: '/casas-en-venta',
      name: 'sale',
      sections: [],
    },
    {
      url: '/formulario-venta',
      name: 'buy',
      sections: [],
    },
    {
      url: '/casas-en-alquiler',
      name: 'rent',
      sections: [],
    },
    {
      url: '/hipotecas',
      name: 'mortgage',
      sections: [],
    },
    {
      url: '/vende-tu-casa',
      name: 'salesBrokerage',
      sections: [],
    },
  ],
  socialMedia: [{ name: '', url: '' }],
  zones: [
    {
      address: '',
      name: '',
      phone: '',
      phonePrivateArea: '',
      url: '',
    },
  ],
  country: 'es',
  countries: [
    { url: 'fr.clikalia.com', name: 'france' },
    { url: 'es.clikalia.com', name: 'spain' },
    { url: 'pt.clikalia.com', name: 'portugal' },
    { url: 'mx.clikalia.com', name: 'mexico' },
  ],
  payments: {
    rent: {
      price: 200,
    },
    sales: {
      tax: 1.21,
      price: 1000,
      managementPrice: 2900,
    },
  },
};

const initialHeaderConfig: HeaderConfig = {
  sections: [
    {
      url: '',
      label: '',
      active: false,
    },
  ],
  contact: {
    email: '',
    phone: '',
    country: '',
  },
};

const currentHomeUrl = process.env.NEXT_PUBLIC_HOME_URL;
const isPrivateArea = currentHomeUrl === '/area-privada' || currentHomeUrl === '/espace-prive';

export const GeneralContext = createContext<GeneralContextProps<SiteConfig>>({
  header: initialHeaderConfig,
  siteConfig: defaultSiteConfig,
});

const GeneralContextProvider = ({
  children,
  initialSiteConfig,
}: GeneralContextProviderProps<SiteConfig>) => {
  const { location, countryCode } = useContext(LocalizationContext);
  const { t } = useTranslation('common');
  const router = useRouter();

  const { basePath, asPath, locale, defaultLocale } = router;

  const [siteConfiguration, setSiteConfiguration] = useState<SiteConfig>(
    initialSiteConfig || defaultSiteConfig
  );
  const zone = useZones({ zones: siteConfiguration.zones, location, countryCode });

  const getHeaderConfig = useCallback((): HeaderConfig => {
    const headerSections: HeaderConfig['sections'] = siteConfiguration?.header.map(
      ({ url, name }) => ({
        url: convertStrapiURLWithLocale({ url, locale, defaultLocale }),
        label: t(`header.sections.${name}` as TFuncKey) as string,
        active: url === (basePath || asPath),
      })
    );

    if (!headerSections) {
      return initialHeaderConfig;
    }

    return {
      sections: headerSections,
      contact: {
        email: siteConfiguration?.email || 'hola@clikalia.com',
        phone: isPrivateArea ? zone?.phonePrivateArea || '' : zone?.phone || '',
        country: siteConfiguration?.country || 'es',
      },
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteConfiguration, zone, t]);

  const [headerConfig, setHeaderConfig] = useState<HeaderConfig>(getHeaderConfig());

  useEffect(() => {
    if (
      initialSiteConfig &&
      JSON.stringify(initialSiteConfig) !== JSON.stringify(siteConfiguration)
    ) {
      setSiteConfiguration(initialSiteConfig);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialSiteConfig]);

  useEffect(() => {
    setHeaderConfig(getHeaderConfig());
  }, [getHeaderConfig]);

  const contextValue = useMemo<GeneralContextProps<SiteConfig>>(
    () => ({
      header: headerConfig,
      siteConfig: siteConfiguration,
    }),
    [headerConfig, siteConfiguration]
  );

  return <GeneralContext.Provider value={contextValue}>{children}</GeneralContext.Provider>;
};

export default GeneralContextProvider;
