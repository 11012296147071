import React, { ChangeEventHandler, useCallback, useContext, useState } from 'react';
import { InputAutocomplete } from '@clikaliatech/clikalia-ui';
import googlePlacesService from '../../../api/googlePlaces.service';
import { getAddressComponents } from './utils/getAddressComponents';
import {
  AutocompleteResults,
  InputAddressAutocompleteProps,
} from './InputAddressAutocomplete.types';

import { InputAddressAutocompleteWrapper } from './InputAddressAutocomplete.styled';

import { AcquisitionsContext } from '@contexts/AcquisitionsContext';
import { LocalizationContext } from 'clikalia-web-components';

const InputAddressAutocomplete: React.FC<InputAddressAutocompleteProps> = ({
  value,
  onChange,
  onSelect,
  name,
  placeholder,
  label = '',
  testId = 'inputAddress',
}) => {
  const [autocompleteResults, setAutocompleteResults] = useState<AutocompleteResults>();
  const { countryCode, localeFormatted } = useContext(LocalizationContext);
  const { setPropertyValue } = useContext(AcquisitionsContext);

  const setContextValues = (
    addressComponents: google.maps.GeocoderAddressComponent[],
    lat?: number,
    long?: number
  ) => {
    const propertyAddressValues = getAddressComponents(addressComponents);
    setPropertyValue({
      location: {
        ...propertyAddressValues,
        lat: lat?.toString(),
        long: long?.toString(),
      },
    });

    onSelect(propertyAddressValues?.street);
    onChange(propertyAddressValues?.street);
  };

  const handleAddressOnChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    async ({ target }) => {
      const currentInput = target.value;
      const isTextValid = currentInput && currentInput.length > 0;

      onChange(currentInput);

      if (isTextValid) {
        try {
          const placesResponse = await googlePlacesService.searchAddress(
            currentInput,
            countryCode.toLocaleLowerCase(),
            localeFormatted
          );
          const autocompleteResults: AutocompleteResults = placesResponse?.predictions.map(
            (prediction) => ({ label: prediction.description, value: prediction.place_id })
          );
          setAutocompleteResults(autocompleteResults);
        } catch {}
      } else {
        setAutocompleteResults(undefined);
      }
    },
    [onChange, countryCode, localeFormatted]
  );

  const handleOnSelectOption = (placeId: string) => {
    setAutocompleteResults(undefined);

    googlePlacesService.getDetails(placeId, (response) => {
      if (response && response.address_components) {
        const { address_components, geometry } = response;
        const lat = geometry?.location?.lat();
        const long = geometry?.location?.lng();

        setContextValues(address_components, lat, long);
      }
      googlePlacesService.refreshToken();
    });
  };

  return (
    <InputAddressAutocompleteWrapper>
      <InputAutocomplete
        name={name}
        testId={testId}
        value={value}
        placeholder={placeholder}
        label={label}
        onChange={handleAddressOnChange}
        autoFilterOptions={false}
        options={autocompleteResults}
        onSelect={handleOnSelectOption}
        fullWidth
      />
    </InputAddressAutocompleteWrapper>
  );
};

export default InputAddressAutocomplete;
