import styled from 'styled-components';
import { HeaderContactWrapperProps } from './HeaderContact.types';
import { HeaderProps } from '../../Header.types';

const HeaderContactStyled = styled.a<HeaderContactWrapperProps>(({ theme, transparent }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  textDecoration: 'none',
  backgroundColor: transparent ? 'rgba(255, 241, 241, 0.3)' : 'transparent',
  width: '38px',
  height: '38px',
  borderRadius: '50%',
  [theme.breakpoints.up('sm')]: {
    backgroundColor: 'transparent',
    width: 'inherit',
    height: 'inherit',
    borderRadius: 'inherit',
  },
}));

const HeaderContactPhoneStyled = styled.span<Pick<HeaderProps, 'transparent'>>(
  ({ theme, transparent }) => ({
    fontWeight: 600,
    fontSize: theme.size(1.8),
    lineHeight: theme.size(2.3),
    color: transparent ? theme.palette.white : theme.palette.primary,
    margin: theme.spacing(0, 1),
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'inherit',
    },
  })
);

export { HeaderContactStyled, HeaderContactPhoneStyled };
