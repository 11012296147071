import styled from 'styled-components';

export const InputAddressAutocompleteWrapper = styled.div(({ theme }) => ({
  width: '100%',

  input: {
    fontWeight: 700,
    fontSize: theme.size(2),
    padding: theme.spacing(4, 0),
    textAlign: 'center',
    borderWidth: theme.spacing(0, 0, 0.25),
    borderColor: theme.palette.white,
    color: theme.palette.white,

    '&:focus, &:active, &:hover': {
      borderColor: theme.palette.white,
      borderWidth: theme.spacing(0, 0, 0.25),
    },

    [theme.breakpoints.up('md')]: {
      fontSize: theme.size(4),
    },
  },

  ul: {
    background: 'transparent',

    li: {
      background: theme.palette.black,
      opacity: '0.55',
      borderRadius: '6px',
      borderColor: 'transparent',

      '> div': {
        color: theme.palette.white,
      },
    },
  },
}));
