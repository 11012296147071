import { Button, Icon } from '@clikaliatech/clikalia-ui';
import {
  TabsHomeWrapper,
  TabsHomeWrapperButton,
  TabsHomeListWrapper,
  TabsHomeListItem,
  TabsHomeListItemAnchor,
  TabsHomeListItemArrowWrapper,
} from './TabsHome.styled';
import { TabsHomeProps } from './TabsHome.types';

const TabsHome = ({
  tabs,
  activeStep,
  onChangeSlide,
  buttonText,
  onClickButton,
  faqsIndexAt,
  hideCta = false,
  testId = 'tabsHome',
}: TabsHomeProps) => {
  const onClickAnchor = (tab: number) => {
    onChangeSlide(tab);
  };
  return (
    <TabsHomeWrapper>
      {!hideCta && (
        <TabsHomeWrapperButton>
          <Button
            variant={Button.Variants.Primary}
            size={Button.Sizes.Medium}
            onClick={onClickButton}
            testId={`tabs-cta-${testId}`}
          >
            {buttonText}
          </Button>
        </TabsHomeWrapperButton>
      )}

      <TabsHomeListWrapper role="list">
        {tabs.map((tab, index) => (
          <TabsHomeListItem key={`${tab.href}-${index}`} role="listitem">
            <TabsHomeListItemAnchor
              onClick={() => onClickAnchor(tab.indexRelated + 1)}
              isSelected={activeStep === tab.indexRelated}
              isFaqs={activeStep === faqsIndexAt}
              tabIndex={index}
              href={`#${tab.href}`}
              data-testid={`tabs-anchor-${testId}-${index}`}
            >
              {tab.label}
            </TabsHomeListItemAnchor>

            {activeStep === tab.indexRelated && (
              <TabsHomeListItemArrowWrapper>
                <Icon name="triangle" color={Icon.Colors.PRIMARY} variant={Icon.Variants.SHARP} />
              </TabsHomeListItemArrowWrapper>
            )}
          </TabsHomeListItem>
        ))}
      </TabsHomeListWrapper>
    </TabsHomeWrapper>
  );
};

export default TabsHome;
