import React, { useMemo } from 'react';
import { useTranslation } from 'next-i18next';
import { TypographyOld as Typography } from '@clikaliatech/clikalia-ui';
import { CarouselReviews } from 'clikalia-web-components';

import { homeSections } from 'cross-config/utils/home/homeSections';

import {
  HomeAcquisitionsReviewsWrapper,
  HomeAcquisitionsReviewsContentHeader,
  CarouselReviewsWrapper,
} from './HomeAcquisitionsReviews.styled';

const HomeAcquisitionsReviews = () => {
  const { t } = useTranslation('acquisitions');

  const reviews = useMemo(
    () => [
      {
        author: t('acquisitions.reviews.firstReview.title'),
        review: t('acquisitions.reviews.firstReview.description'),
        score: 4,
      },
      {
        author: t('acquisitions.reviews.twoReview.title'),
        review: t('acquisitions.reviews.twoReview.description'),
        score: 3,
      },
      {
        author: t('acquisitions.reviews.threeReview.title'),
        review: t('acquisitions.reviews.threeReview.description'),
        score: 2,
      },
      {
        author: t('acquisitions.reviews.fourReview.title'),
        review: t('acquisitions.reviews.fourReview.description'),
        score: 4,
      },
      {
        author: t('acquisitions.reviews.fiveReview.title'),
        review: t('acquisitions.reviews.fiveReview.description'),
        score: 5,
      },
      {
        author: t('acquisitions.reviews.sixReview.title'),
        review: t('acquisitions.reviews.sixReview.description'),
        score: 3,
      },
    ],
    [t]
  );

  return (
    <HomeAcquisitionsReviewsWrapper id={homeSections.Acquisitions.reviews}>
      <HomeAcquisitionsReviewsContentHeader>
        <Typography type={Typography.Types.H3}>{t('acquisitions.reviews.title')}</Typography>
        <Typography type={Typography.Types.B2Regular}>
          {t('acquisitions.reviews.description')}
        </Typography>
      </HomeAcquisitionsReviewsContentHeader>
      <CarouselReviewsWrapper>
        <CarouselReviews dataSource={reviews} />
      </CarouselReviewsWrapper>
    </HomeAcquisitionsReviewsWrapper>
  );
};

export default HomeAcquisitionsReviews;
