import { useContext, Children } from 'react';
import { Icon, TypographyOld as Typography } from '@clikaliatech/clikalia-ui';

import { FooterContext } from '../../Footer.context';
import {
  SocialWrapperStyled,
  SocialMediaIconsWrapperStyled,
  SocialMediaIconStyled,
} from './FooterSocial.styled';
import { FooterSocialProps, FooterSocialLinkProps, SocialMediaIcons } from './FooterSocial.types';

type IconNameType = keyof typeof Icon.Names;
function FooterSocial({ children, title }: FooterSocialProps) {
  const isValidChildren = Children.toArray(children).length > 0;

  if (!isValidChildren) return null;

  return (
    <SocialWrapperStyled data-testid="footerSocial">
      {title && (
        <Typography type={Typography.Types.B1Regular} color="white">
          {title}
        </Typography>
      )}
      <SocialMediaIconsWrapperStyled>{children}</SocialMediaIconsWrapperStyled>
    </SocialWrapperStyled>
  );
}

const FooterSocialLink: React.FC<FooterSocialLinkProps> & {
  IconNames: typeof SocialMediaIcons;
} = ({ name, url }) => {
  const { testId } = useContext(FooterContext);

  if (!name) return null;

  return (
    <SocialMediaIconStyled
      href={url}
      aria-label={`${testId}-${name}-link`}
      data-testid={`${testId}${name}SocialMedia`}
    >
      <Icon
        name={SocialMediaIcons[name] as unknown as IconNameType}
        color={Icon.Colors.INHERIT}
        variant={Icon.Variants.BRANDS}
      />
    </SocialMediaIconStyled>
  );
};

FooterSocial.displayName = 'footerSocial';
FooterSocialLink.displayName = 'footerSocialLink';

FooterSocialLink.IconNames = SocialMediaIcons;
FooterSocial.Link = FooterSocialLink;

export default FooterSocial;
