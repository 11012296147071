import { defaultTheme, StepIndicator } from '@clikaliatech/clikalia-ui';

import { ControlSliderDotsWrapper } from './ControlSliderDots.styled';
import { ControlSliderDotsProps } from './ControlSliderDots.types';

const ControlSliderDots: React.FC<ControlSliderDotsProps> = ({
  testId = 'dotsComponent',
  activeStep,
  steps,
}) => {
  return (
    <ControlSliderDotsWrapper>
      <StepIndicator
        activeStep={activeStep}
        steps={steps}
        activeColor={defaultTheme.palette.white}
        testId={`${testId}-stepIndicator`}
      />
    </ControlSliderDotsWrapper>
  );
};

export default ControlSliderDots;
