import { useContext } from 'react';

import { FooterContext } from '../../Footer.context';
import { FooterSection } from './components';
import { FooterSectionsStyled } from './FooterSections.styled';
import { FooterSectionsProps } from './FooterSections.types';

function FooterSections({ onClick, children }: FooterSectionsProps) {
  const { testId } = useContext(FooterContext);

  if (!children) return null;

  return (
    <FooterSectionsStyled data-testid={`${testId}SectionsComponent`} onClick={onClick}>
      {children}
    </FooterSectionsStyled>
  );
}

FooterSections.displayName = 'footerSections';
FooterSections.Section = FooterSection;

export default FooterSections;
