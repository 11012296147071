import { useContext } from 'react';

import ClikaliaLogo from '../../../../../../atoms/ClikaliaLogo';
import { FooterContext } from '../../../../Footer.context';
import { FooterLogoAnchorStyled, FooterLogoWrapperStyled } from './FooterLogo.styled';
import { FooterLogoProps } from './FooterLogo.types';

function FooterLogo({ href }: FooterLogoProps) {
  const { testId } = useContext(FooterContext);

  if (!href) return null;

  return (
    <FooterLogoWrapperStyled>
      <FooterLogoAnchorStyled
        aria-label={`${testId}-logo-link`}
        data-testid={`${testId}LogoComponent`}
        href={href}
      >
        <ClikaliaLogo color="white" />
      </FooterLogoAnchorStyled>
    </FooterLogoWrapperStyled>
  );
}

FooterLogo.displayName = 'footerLogo';

export default FooterLogo;
