import React from 'react';

import ReviewCard from '../ReviewCard';

import Carousel from './components/Carousel';
import { CarouselReviewsProps } from './CarouselReviews.types';

const CarouselReviews: React.FC<CarouselReviewsProps> = ({
  dataSource = [],
  testId = 'carouselReviewsComponent',
}) => {
  return (
    <Carousel testId={testId}>
      {dataSource.map(({ review, score, author }, key) => (
        <ReviewCard
          key={`${author}-${score}-${key}`}
          review={review}
          author={author}
          score={score}
        />
      ))}
    </Carousel>
  );
};

export default CarouselReviews;
