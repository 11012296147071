import React from 'react';
import { defaultTheme, TypographyOld as Typography } from '@clikaliatech/clikalia-ui';

import { BannerGrid, BannerContent, BannerWrapper, Spacing, ButtonWrapper } from './Banner.styled';
import { BannerProps } from './Banner.types';

const Banner: React.FC<BannerProps> = ({
  testId = 'bannerComponent',
  title,
  buttonText,
  url,
  bannerBackgroundImage,
  bannerBackgroundColor,
  buttonBackgroundColor,
  buttonTextColor,
  description,
  descriptionColor,
  titleColor,
  onClick,
}) => {
  const handleOnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();

    onClick && onClick(url);
  };

  return (
    <BannerWrapper
      bannerBackgroundImage={bannerBackgroundImage}
      bannerBackgroundColor={bannerBackgroundColor}
      data-testid={testId}
    >
      <BannerGrid>
        <BannerContent>
          <Typography type={Typography.Types.H3} color={titleColor ?? defaultTheme.palette.white}>
            {title}
          </Typography>
          {description && (
            <Typography
              type={Typography.Types.S2Bold}
              color={descriptionColor ?? defaultTheme.palette.white}
            >
              {description}
            </Typography>
          )}
          <Spacing />
          <ButtonWrapper
            size={ButtonWrapper.Sizes.Medium}
            variant={ButtonWrapper.Variants.Secondary}
            onClick={handleOnClick}
            color={buttonTextColor}
            backgroundColor={buttonBackgroundColor}
          >
            {buttonText}
          </ButtonWrapper>
        </BannerContent>
      </BannerGrid>
    </BannerWrapper>
  );
};

export default Banner;
