import { useMemo } from 'react';
import { FAQs } from 'clikalia-web-components';
import { useTranslation } from 'next-i18next';
import { HomeAcquisitionsFaqsContainer } from './HomeAcquisitionsFaqs.styled';
import { homeSections } from 'cross-config/utils/home/homeSections';

const HomeAcquisitionsFaqs = () => {
  const { t } = useTranslation('acquisitions');

  const faqsAcquisitions = useMemo(
    () => [
      {
        title: t('acquisitions.faqs.faqOne.title'),
        description: t('acquisitions.faqs.faqOne.description'),
      },
      {
        title: t('acquisitions.faqs.faqTwo.title'),
        description: t('acquisitions.faqs.faqTwo.description'),
      },
      {
        title: t('acquisitions.faqs.faqThree.title'),
        description: t('acquisitions.faqs.faqThree.description'),
      },
      {
        title: t('acquisitions.faqs.faqFour.title'),
        description: t('acquisitions.faqs.faqFour.description'),
      },
      {
        title: t('acquisitions.faqs.faqFive.title'),
        description: t('acquisitions.faqs.faqFive.description'),
      },
      {
        title: t('acquisitions.faqs.faqSix.title'),
        description: t('acquisitions.faqs.faqSix.description'),
      },
    ],
    [t]
  );

  return (
    <HomeAcquisitionsFaqsContainer id={homeSections.Acquisitions.faqs}>
      <FAQs
        title={t('acquisitions.faqs.title')}
        backTitle={t('acquisitions.faqs.backtitle')}
        faqs={faqsAcquisitions}
      />
    </HomeAcquisitionsFaqsContainer>
  );
};

export default HomeAcquisitionsFaqs;
