import styled from 'styled-components';

import { FooterProps } from './Footer.types';
import { defaultTheme } from '@clikaliatech/clikalia-ui';

type keysTypeofPalette = keyof typeof defaultTheme.palette;

export const FooterStyled = styled.footer<FooterProps>(
  ({ theme, backgroundColor = 'black', cover }) => ({
    padding: theme.spacing(1, 2, 1, 2),
    color: theme.palette.white,
    backgroundImage: cover ? `url(${cover})` : 'none',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      backgroundColor in theme.palette
        ? (theme.palette as never)[backgroundColor as keysTypeofPalette]
        : backgroundColor,

    display: 'flex',
    justifyContent: 'center',

    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(2, 4, 1, 4),
    },
  })
);

export const FooterBodyStyled = styled.div(({ theme }) => ({
  height: '100%',
  alignContent: 'end',

  display: 'grid',
  gridTemplateAreas: `
  "contact"
  "regions"
  "social"
  "bottom"
`,
  gridTemplateRows: '4fr',

  [theme.breakpoints.up('md')]: {
    alignItems: 'end',
    alignContent: 'inherit',

    gridTemplateAreas: `
    "contact"
    "social"
    "regions"
    "bottom"
  `,

    gridTemplateRows: '5fr 2fr',
  },
}));

export const FooterBodyContentStyled = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-around',
  gap: theme.spacing(2),
  padding: theme.spacing(3, 1, 8, 1),

  alignSelf: 'baseline',

  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    padding: theme.spacing(4, 2),
    alignSelf: 'end',
  },
}));

export const FooterContent = styled.div({
  maxWidth: '1360px',
  height: '100%',
  width: '100%',
});
