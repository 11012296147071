import { TypographyOld as Typography } from '@clikaliatech/clikalia-ui';
import { useContext } from 'react';

import { FooterContext } from '../../../../Footer.context';
import { FooterCopyrightStyled } from './FooterCopyright.styled';
import { CopyrightProps } from './FooterCopyright.types';

const FooterCopyright: React.FC<CopyrightProps> = ({ children }) => {
  const { testId } = useContext(FooterContext);
  return (
    <FooterCopyrightStyled data-testid={`${testId}Copyright`}>
      <Typography type={Typography.Types.B3Medium} color="inherit">
        {children}
      </Typography>
    </FooterCopyrightStyled>
  );
};

FooterCopyright.displayName = 'footerCopyright';

export default FooterCopyright;
