import React from 'react';
import { defaultTheme, Icon, TypographyOld as Typography } from '@clikaliatech/clikalia-ui';

import OverflowedWrapper from '../OverflowedWrapper';
import { DescriptionPanelProps } from './DescriptionPanel.types';
import { DescriptionPanelWrapper } from './DescriptionPanel.styled';

const Description: React.FC<DescriptionPanelProps> = ({ text }) => {
  return (
    <DescriptionPanelWrapper>
      <OverflowedWrapper
        maxHeight={'340px'}
        icon={<Icon name="chevron-down" color={Icon.Colors.WHITE} variant={Icon.Variants.SHARP} />}
      >
        <Typography type={Typography.Types.B2Regular} color={defaultTheme.palette.lightSalmon}>
          {text}
        </Typography>
      </OverflowedWrapper>
    </DescriptionPanelWrapper>
  );
};

export default Description;
