import styled from 'styled-components';

export const FooterLegalLinkStyled = styled.a(({ theme }) => ({
  color: theme.palette.gray,
  fontSize: theme.size(1.4),
  textDecoration: 'none',
  gridColumn: 'auto / span 2',
  textAlign: 'center',

  [theme.breakpoints.up('sm')]: {
    gridColumn: 'auto / span 1',
    textAlign: 'initial',
  },
}));
