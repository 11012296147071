import styled from 'styled-components';

export const HomeAcquisitionsFaqsContainer = styled.div(
  ({ theme: { palette, breakpoints, spacing } }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: palette.black,
    height: '100%',
    width: '100%',
    paddingTop: spacing(8),
    [breakpoints.up('sm')]: {
      paddingTop: 0,
    },
  })
);
