import styled from 'styled-components';

const CoverWrapper = styled.section(({ theme: { button, spacing, breakpoints } }) => ({
  display: 'none',
  [breakpoints.up('sm')]: {
    display: 'grid',
    gridTemplateRows: '3fr 1fr',
    gridTemplateColumns: '1fr',
    alignItems: 'center',
    justifyItems: 'center',
    justifyContent: 'center',
    borderRadius: 10,
    background: 'rgba(54, 50, 50, 0.6)',
    width: '100%',
    maxWidth: '780px',
    minHeight: '315px',
    padding: spacing(2),
    '& > button': {
      ...button.size.large,
    },
  },
}));

const SectionNamesStyled = styled.div(({ theme: { spacing, breakpoints } }) => ({
  display: 'none',
  [breakpoints.up('sm')]: {
    display: 'flex',
    width: '100%',
    maxWidth: '780px',
    justifyContent: 'space-around',
    marginTop: spacing(1),

    div: {
      display: 'flex',
    },
  },
}));

const SectionNameElement = styled.button<{ isActive: boolean }>(
  ({ theme: { palette, typography, breakpoints }, isActive }) => ({
    ...typography.b1Bold,
    color: palette.white,
    cursor: 'pointer',
    borderRadius: 0,
    background: 'none',
    borderColor: 'none',
    border: 'none',
    borderBottom: isActive ? `3px solid ${palette.primary}` : '3px solid transparent',
    padding: 0,
    gap: 0,
    transition: 'none',
    [breakpoints.up('sm')]: {
      ...typography.h3,
      fontWeight: 600,
    },
    '&:hover': {
      color: palette.white,
      background: 'unset',
      borderColor: 'unset',
      borderBottom: `3px solid ${palette.primary}`,
    },
    '&:active': {
      color: palette.white,
      background: 'unset',
      borderColor: 'unset',
      border: 'none',
      transform: 'scale(0.87)',
    },
  })
);

const DividerStyled = styled.span(({ theme: { spacing, palette } }) => ({
  background: palette.white,
  height: 'auto',
  width: '3px',
  margin: spacing(1.5, 2),
  borderRadius: 20,
}));

const CarrouselWrapper = styled.div(({ theme: { spacing, breakpoints } }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  borderRadius: 10,
  background: 'rgba(54, 50, 50, 0.6)',
  width: '100%',
  maxWidth: '780px',
  height: '380px',
  padding: spacing(2),
  position: 'relative',
  paddingBottom: spacing(5),
  [breakpoints.up('sm')]: {
    display: 'none',
  },
}));

const CarrouselElement = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const StepIndicatorsContainer = styled.div(({ theme }) => ({
  position: 'absolute',
  bottom: theme.spacing(2),
}));

export {
  CoverWrapper,
  SectionNamesStyled,
  SectionNameElement,
  CarrouselWrapper,
  CarrouselElement,
  StepIndicatorsContainer,
  DividerStyled,
};
