import styled from 'styled-components';

export const FooterBottomStyled = styled.div(({ theme }) => ({
  width: '100%',
  display: 'grid',
  justifyItems: 'center',
  justifyContent: 'center',
  paddingTop: theme.spacing(4),

  gridArea: 'bottom',
}));
