import React, { useState, useEffect, useRef } from 'react';
import { isVerticalScrollbarVisible } from 'cross-config/utils/dom';

import { Wrapper, ChildrenWrapper, ArrowWrapper } from './OverflowedWrapper.styled';
import { OverflowedWrapperProps } from './OverflowedWrapper.types';

const OverflowedWrapper: React.FC<OverflowedWrapperProps> = ({
  children,
  maxHeight,
  icon,
  testId = 'overflowedWrapperComponent',
}) => {
  const [hasOverflow, setHasOverflow] = useState<boolean>(false);

  const parentRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    const parentElement = parentRef.current!;

    parentElement.style.scrollBehavior = 'smooth';
    parentElement.scrollTop = parentElement.scrollHeight;
  };

  useEffect(() => {
    const isOverflown = isVerticalScrollbarVisible(parentRef.current!);
    setHasOverflow(isOverflown);

    return () => {
      setHasOverflow(false);
    };
  }, [children]);

  useEffect(() => {
    const parentElement = parentRef.current!;
    const handleScroll = () => {
      if (
        Math.round(parentElement.scrollTop) + parentElement.clientHeight >=
        parentElement.scrollHeight
      ) {
        setHasOverflow(false);
      } else {
        setHasOverflow(true);
      }
    };

    parentElement.addEventListener('scroll', handleScroll, true);
    return () => {
      parentElement.removeEventListener('scroll', handleScroll, true);
    };
  }, []);

  return (
    <Wrapper id={testId} data-testid={testId}>
      <ChildrenWrapper ref={parentRef} data-testid={`${testId}-Children`} maxHeight={maxHeight}>
        {children}
      </ChildrenWrapper>
      <ArrowWrapper
        id={`${testId}-Arrow`}
        data-testid={`${testId}-Arrow`}
        visible={hasOverflow}
        onClick={scrollToBottom}
      >
        {icon}
      </ArrowWrapper>
    </Wrapper>
  );
};

export default OverflowedWrapper;
