import styled from 'styled-components';

export const HomeCoverStyle = styled.div(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  padding: theme.spacing(0, 2),

  [theme.breakpoints.up('sm')]: {
    padding: 0,
  },

  [theme.breakpoints.down('lg', 'landscape')]: {
    padding: theme.spacing(5, 2.5),
  },
}));

export const HomeCoverBackgroundImage = styled.img({
  zIndex: -1,
  objectFit: 'cover',
  objectPosition: 'center',
  position: 'absolute',
  height: '100%',
  width: '100%',
  left: 0,
  top: 0,
  right: 0,
  bottom: 0,
});

export const HomeCoverNewsWrapper = styled.div(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(3.75),
  marginTop: theme.spacing(4),
  overflowX: 'scroll',
  scrollbarWidth: 'none',
  '-ms-overflow-style': 'none',
  '::-webkit-scrollbar': {
    display: 'none',
  },
  width: 'inherit',
  [theme.breakpoints.up('sm')]: {
    gap: theme.spacing(6),
    justifyContent: 'center',
  },
}));
