import styled from 'styled-components';

export const ControlSliderDotsWrapper = styled.div(({ theme }) => ({
  display: 'none',

  /* smartphones, touchscreens */
  /* Source: https://ferie.medium.com/detect-a-touch-device-with-only-css-9f8e30fa1134 */
  '@media (hover: none) and (pointer: coarse)': {
    width: '100%',
    paddingTop: theme.spacing(2),
    display: 'block',
  },
}));
