import styled from 'styled-components';
import { TabsHomeListItemAnchorProps } from './TabsHome.types';

export const TabsHomeWrapper = styled.div(({ theme }) => ({
  display: 'flex',
  width: '100%',
  flexDirection: 'column-reverse',
  position: 'absolute',
  bottom: theme.spacing(2),
  [theme.breakpoints.up('sm')]: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
}));

export const TabsHomeWrapperButton = styled.div({
  display: 'flex',
  justifyContent: 'center',
});

export const TabsHomeListWrapper = styled.ul(({ theme }) => ({
  overflowX: 'auto',
  flexDirection: 'column',
  justifyContent: 'center',
  height: 65,
  listStyleType: 'disclosure-closed',
  display: 'none',
  [theme.breakpoints.up('sm')]: {
    display: 'flex',
    flexDirection: 'row',
    alignTtems: 'flex-end',
    paddingInlineStart: 0,
    flex: 1,
    listStyleType: 'none',
  },
}));

export const TabsHomeListItem = styled.li(({ theme }) => ({
  position: 'relative',
  color: theme.palette.black,
  margin: theme.spacing(0, 2, 0, 2),
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'end',

  [theme.breakpoints.up('sm')]: {
    display: 'flex',
    alignItems: 'center',
  },
}));

export const TabsHomeListItemAnchor = styled.a<TabsHomeListItemAnchorProps>(
  ({ theme, isSelected, isFaqs }) => ({
    position: 'relative',
    color: isSelected ? (isFaqs ? theme.palette.white : theme.palette.black) : theme.palette.gray,
    cursor: 'pointer',
    fontSize: theme.size(1.6),
    fontWeight: 700,
    textDecoration: 'none',

    [theme.breakpoints.up('sm')]: {
      fontSize: theme.size(2.0),
      transition: 'bottom 0.2s ease 0s',
      whiteSpace: 'pre-wrap',
      textAlign: 'center',
    },
  })
);

export const TabsHomeListItemArrowWrapper = styled.div(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.up('sm')]: {
    display: 'block',
    height: 25,
  },
}));
