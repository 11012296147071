import { useContext } from 'react';
import { Icon, TypographyOld as Typography } from '@clikaliatech/clikalia-ui';

import { FooterContext } from '../../../../Footer.context';
import { ContactItemsStyled, ContactItemsLinkStyled } from '../../FooterContact.styled';
import { FooterEmailProps } from './FooterEmail.types';

const FooterEmail: React.FC<FooterEmailProps> = ({ email }) => {
  const { testId } = useContext(FooterContext);

  if (!email) return null;

  return (
    <ContactItemsStyled>
      <Icon name="email" variant={Icon.Variants.SHARP} color={Icon.Colors.WHITE} />
      <ContactItemsLinkStyled
        href={`mailto:${email}`}
        data-testid={`${testId}Email`}
        isRemoveBoldStyleInDesktopDevices
      >
        <Typography type={Typography.Types.B1Bold} color="white">
          {email}
        </Typography>
      </ContactItemsLinkStyled>
    </ContactItemsStyled>
  );
};

FooterEmail.displayName = 'footerEmail';

export default FooterEmail;
