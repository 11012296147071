import styled from 'styled-components';
import { HomeAddressProps } from './HomeAddress.types';

export const HomeAddressWrapper = styled.div(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  width: '100%',
  padding: theme.spacing(0, 2),

  [theme.breakpoints.up('sm')]: {
    padding: 0,
  },

  [theme.breakpoints.down('lg', 'landscape')]: {
    padding: `${theme.size(15)} ${theme.size(5)}`,
  },
}));

export const HomeAddressBackgroundImage = styled.img({
  objectFit: 'cover',
  objectPosition: 'center',
  position: 'absolute',
  height: '100%',
  width: '100%',
  inset: '0px',
});

export const HomeAddressInputWrapper = styled.div(({ theme }) => ({
  width: 'inherit',
  maxWidth: '600px',

  '& input::placeholder': {
    color: theme.palette.white,
  },
  'div ul li': { opacity: 0.95 },
}));

export const HomeAddressBackWrapper = styled.div<Partial<HomeAddressProps>>(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(0.5, 1),
  alignItems: 'center',
  cursor: 'pointer',
  position: 'absolute',
  top: '80px',
  left: theme.spacing(2),

  [theme.breakpoints.down('lg', 'landscape')]: {
    top: '10px',
  },
}));

export const HomeAddressStepsWrapper = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  maxWidth: '780px',
  justifyContent: 'space-around',
  marginTop: theme.spacing(1),
  gap: theme.spacing(0.25),

  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
  },
}));

export const DividerStyled = styled.span(({ theme: { spacing, palette, breakpoints } }) => ({
  position: 'relative',
  background: palette.white,
  height: '100%',
  width: '3px',
  margin: spacing(0, 2),
  borderRadius: 20,

  [breakpoints.up('sm')]: {
    height: '20px',
  },
}));

export const InputFooterWrapper = styled.div<{ visible: boolean }>(({ theme, visible }) => ({
  position: 'relative',
  display: 'grid',
  gridTemplateColumns: '1fr',
  visibility: visible ? 'visible' : 'hidden',
  padding: theme.spacing(2),
  height: '100px',
}));
