enum Acquisitions {
  sellYourHome = 'sellYourHome',
  howSellWorks = 'howSellWorks',
  whyClikalia = 'whyClikalia',
  whatWeOffer = 'whatWeOffer',
  reviews = 'reviews',
  faqs = 'faqs',
}

enum Sales {
  salesYourHome = 'salesYourHome',
  howSalesWorks = 'howSalesWorks',
  salesBenefits = 'salesBenefits',
  faqs = 'faqs',
  reviews = 'reviews',
}

enum Rent {
  rentAHome = 'rentAHome',
  howRentWorks = 'howRentWorks',
  rentBenefits = 'rentBenefits',
  additionalServices = 'additionalServices',
  faqs = 'faqs',
}

enum Mortgages {
  calculateMortgage = 'calculateMortgage',
  howMortgagesWorks = 'howMortgagesWorks',
  whatWeOffer = 'whatWeOffer',
  partners = 'partners',
  reviews = 'reviews',
  faqs = 'faqs',
}

export const homeSections = {
  Acquisitions,
  Sales,
  Rent,
  Mortgages,
};

export type homeSections = typeof homeSections;
