import { useState } from 'react';

import { UseControlSliderProps, UseControlSliderResponse } from './useControlSlider.types';

const useControlSlider = ({
  sliders,
  infinite,
  initialState = 1,
  initialDot = 1,
}: UseControlSliderProps): UseControlSliderResponse => {
  const INITIAL_VALUE_SLIDE = initialState;
  const LAST_VALUE_SLIDE = sliders;

  const [slide, setSlide] = useState(INITIAL_VALUE_SLIDE);
  const [dotActive, setDotActive] = useState(initialDot);

  const handleOnNextSlide = (): void => {
    const isNextAvailable = slide < LAST_VALUE_SLIDE;
    const nextStep = slide + 1;

    if (infinite) {
      isNextAvailable ? setSlide(nextStep) : setSlide(INITIAL_VALUE_SLIDE);
    } else if (isNextAvailable) {
      setSlide(nextStep);
    }
  };

  const handleOnPrevSlide = (): void => {
    const isPrevAvailable = slide > INITIAL_VALUE_SLIDE;
    const prevStep = slide - 1;

    if (infinite) {
      isPrevAvailable ? setSlide(prevStep) : setSlide(LAST_VALUE_SLIDE);
    } else if (isPrevAvailable) {
      setSlide(prevStep);
    }
  };

  const handleOnDotsChange = (currentDot: number): void => {
    setDotActive(currentDot);
  };

  const handleOnSlideChange = (currentSlide: number): void => {
    setSlide(currentSlide);
  };

  return {
    slide,
    dotActive,
    onNext: handleOnNextSlide,
    onPrev: handleOnPrevSlide,
    onDotsChange: handleOnDotsChange,
    onSlideChange: handleOnSlideChange,
  };
};

export default useControlSlider;
