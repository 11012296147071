import styled from 'styled-components';

export const ItemFlagStyled = styled.span(({ theme }) => ({
  fontSize: theme.size(2),
  marginRight: theme.spacing(1),
  fontFamily: `'TwemojiCountryFlags'`,
  display: 'inline-block',
  verticalAlign: 'text-bottom',
}));

export const FooterRegionsWrapper = styled.div(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',

  gridArea: 'regions',

  [theme.breakpoints.up('md')]: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: theme.spacing(6),

    a: {
      paddingRight: theme.spacing(6),
    },
    'a:last-child': {
      paddingRight: 0,
    },
  },
}));

export const RegionWrapper = styled.a(({ theme }) => ({
  textDecoration: 'none',
  color: theme.palette.white,
  padding: theme.spacing(0, 0, 3, 0),
  ...theme.typography.b2Medium,

  [theme.breakpoints.up('md')]: {
    display: 'flex',
    justifyContent: 'center',
    padding: 0,
  },
}));
