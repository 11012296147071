import styled from 'styled-components';

export const BannerAdsMarketingMiddleWrapper = styled.section({
  height: '130px',
  minHeight: '130px',
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
});

export const BannerAdsMarketingMiddleIconWrapper = styled.div(({ theme }) => ({
  height: 20,
  position: 'absolute',
  right: 10,
  top: 0,
  zIndex: theme.zIndex.navBar,
  cursor: 'pointer',
  '> span': {
    zIndex: 1,
    width: '100%',
    height: '100%',
    display: 'grid',
    alignContent: 'center',
  },
}));
