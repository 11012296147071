import React from 'react';
import { TypographyOld as Typography } from '@clikaliatech/clikalia-ui';

import { ViewHowWorkWrapper, Content, Footer } from './ViewHowWork.styled';
import { ViewHowWorkProps } from './ViewHowWork.types';

const ViewHowWork: React.FC<ViewHowWorkProps> = ({
  title,
  description,
  picture,
  testId,
  alt,
  colorTitle,
  active = false,
}) => {
  return (
    <ViewHowWorkWrapper data-testid={`${testId}-viewHowWorkComponent`}>
      <Content>
        {/* eslint-disable-next-line @next/next/no-img-element */}
        <img src={picture} alt={alt} />
      </Content>
      <Footer active={active}>
        <Typography type={Typography.Types.B1Bold} color={colorTitle}>
          {title}
        </Typography>
        {active && <Typography type={Typography.Types.B3Regular}>{description}</Typography>}
      </Footer>
    </ViewHowWorkWrapper>
  );
};

export default ViewHowWork;
