import { useContext, useEffect, useState } from 'react';
import { Icon, TypographyOld as Typography } from '@clikaliatech/clikalia-ui';

import { FooterContext } from '../../../../Footer.context';
import { ContactItemsStyled, ContactItemsLinkStyled } from '../../FooterContact.styled';
import { FooterAddressProps } from './FooterAddress.types';

const FooterAddress: React.FC<FooterAddressProps> = ({ address, url }) => {
  const { testId } = useContext(FooterContext);
  // Used this state for prevents hydration issues when address is according user zone.
  // In this case, the address may differ in server and client.
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(true);
  }, []);

  if (!address || !show) return null;

  return (
    <ContactItemsStyled data-testid={`${testId}Address`}>
      <Icon name="pin-map-rounded" variant={Icon.Variants.REGULAR} color={Icon.Colors.WHITE} />
      <ContactItemsLinkStyled
        href={url}
        target="_blank"
        rel="noreferrer"
        data-testid={`${testId}Address-url`}
        isRemoveBoldStyleInDesktopDevices
      >
        <Typography type={Typography.Types.B1Bold} color="white">
          {address.replace(/&nbsp;/g, ' ').replace(/\u00A0/g, ' ')}
        </Typography>
      </ContactItemsLinkStyled>
    </ContactItemsStyled>
  );
};

FooterAddress.displayName = 'footerAddress';

export default FooterAddress;
