import React from 'react';
import { TypographyOld as Typography } from '@clikaliatech/clikalia-ui';

import Score from './components/Score';
import { ReviewCardWrapper, ReviewContainer, ScoreContainer } from './ReviewCard.styled';
import { ReviewCardProps } from './ReviewCard.types';

const ReviewCard: React.FC<ReviewCardProps> = ({
  testId = 'reviewCardComponent',
  review,
  author,
  score,
}) => {
  return (
    <ReviewCardWrapper data-testid={testId}>
      <ReviewContainer>
        <Typography type={Typography.Types.B1Bold}>{author}</Typography>
        <ScoreContainer>
          <Score score={score} />
        </ScoreContainer>
        <Typography type={Typography.Types.B2Medium}>{review}</Typography>
      </ReviewContainer>
    </ReviewCardWrapper>
  );
};

export default ReviewCard;
