import styled from 'styled-components';
import { Button } from '@clikaliatech/clikalia-ui';

import { BannerWrapperProps, ButtonWrapperProps } from './Banner.types';

export const Spacing = styled.div(({ theme }) => ({
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
}));

export const BannerWrapper = styled.section<BannerWrapperProps>(
  ({ bannerBackgroundImage, bannerBackgroundColor }) => ({
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    background: `url(${bannerBackgroundImage}) no-repeat 0, 0`,
    backgroundSize: 'cover',
    ...(!bannerBackgroundImage && {
      '&:after': {
        position: 'absolute',
        content: "' '",
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 0,
        backgroundColor: bannerBackgroundColor,
      },
    }),
  })
);

export const BannerGrid = styled.div(({ theme }) => ({
  width: '100%',
  margin: 'auto',
  zIndex: 1,
  [theme.breakpoints.up('md')]: {
    paddingLeft: theme.spacing(6),
  },
  [theme.breakpoints.up('lg')]: {
    maxWidth: theme.breakpoints.values.xl,
  },
}));

export const BannerContent = styled.div(({ theme }) => ({
  display: 'flex',
  margin: '0 auto',
  alignItems: 'center',
  maxWidth: '720px',
  justifyContent: 'space-between',
  flexDirection: 'column',

  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    '& p:first-child': {
      ...theme.typography.h3,
    },
  },
}));

export const ButtonWrapper = styled(Button)<ButtonWrapperProps>(({ color, backgroundColor }) => ({
  backgroundColor,
  color,
  textDecoration: 'inherit',
  borderRadius: '6px',
  borderColor: backgroundColor,
}));
