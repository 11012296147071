import React, { useState } from 'react';

import HomeCover from '@components/HomeAcquisitions/HomeCover';
import { Section } from '@components/HomeAcquisitions/HomeCover/HomeCover.types';
import homeBackgrounds from '@components/HomeAcquisitions/HomeCover/homeBackgrounds';

import HomeAddress from '../HomeAddress';

const WrapperCoverForm = () => {
  const [showForm, setShowForm] = useState(false);

  const toggleShowForm = () => {
    setShowForm((s) => !s);
  };

  return (
    <>
      {showForm ? (
        <HomeAddress backgroundImage={homeBackgrounds.acquisitions} onBack={toggleShowForm} />
      ) : (
        <HomeCover
          key="homeAcquisitions-cover"
          section={Section.acquisitions}
          backgroundImage={homeBackgrounds.acquisitions}
          onClick={toggleShowForm}
          testId="homeAcquisitions-cover"
        />
      )}
    </>
  );
};

export default WrapperCoverForm;
