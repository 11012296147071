import styled from 'styled-components';
import { CarouselReviewsSliderProps } from './Carousel.types';

export const CarouselWrapper = styled.section(({ theme: { spacing } }) => ({
  display: 'flex',
  height: '100%',
  justifyContent: 'center',
  position: 'relative',
  flexDirection: 'column',
  margin: spacing(1),
}));

export const CarouselGrid = styled.div(({ theme }) => ({
  padding: theme.spacing(0, 6),
  height: '100%',

  /* smartphones, touchscreens */
  /* Source: https://ferie.medium.com/detect-a-touch-device-with-only-css-9f8e30fa1134 */
  '@media (hover: none) and (pointer: coarse)': {
    overflow: 'hidden',
    padding: theme.spacing(0, 1),
  },
}));

export const CarouselLayout = styled.div({
  overflow: 'hidden',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
});

export const CarouselReviewsContainer = styled.div(({ theme: { spacing, breakpoints } }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: spacing(1),

  [breakpoints.down('lg', 'landscape')]: {
    margin: spacing(2, 0),
  },
}));

export const CarouselReviewsSlider = styled.div<CarouselReviewsSliderProps>(
  ({ active, theme }) => ({
    flex: '0 0 100%',
    minWidth: '0px',
    cursor: 'pointer',
    maxWidth: '100%',

    [theme.breakpoints.up('md')]: {
      flex: '0 0 33.33%',
      zIndex: active ? 1 : 0,

      section: {
        opacity: active ? 1 : 0.5,
        transform: `scale(${active ? '1.1' : '.95'})`,
        transition: '0.4s ease transform, 0.4s ease opacity',
      },
    },
  })
);
