import styled from 'styled-components';

import { SectionsSliderContainerProps } from './SectionsSlider.types';

export const SectionsSliderContainer = styled.div<SectionsSliderContainerProps>(
  ({ width, height, theme }) => ({
    width: width ?? '100%',
    height: height ?? '100%',
    display: 'flex',
    flexFlow: 'column nowrap',
    overflow: 'hidden auto',
    scrollSnapType: 'y mandatory',
    scrollBehavior: 'smooth',
    '-ms-overflow-style': 'none',
    scrollbarWidth: 'none',
    '::-webkit-scrollbar': {
      display: 'none',
    },

    [theme.breakpoints.down('lg', 'landscape')]: {
      overflow: 'initial',
      scrollSnapType: 'initial',
      scrollBehavior: 'initial',
    },
  })
);

export const SectionsSliderItem = styled.div(({ theme }) => ({
  scrollSnapAlign: 'center',
  height: '100%',
  flex: 'none',

  [theme.breakpoints.down('lg', 'landscape')]: {
    height: 'auto',
  },
}));

export const SectionsSliderHeader = styled.div(({ theme }) => ({
  position: 'fixed',
  width: '100%',
  zIndex: theme.zIndex.navBar,

  [theme.breakpoints.down('lg', 'landscape')]: {
    position: 'relative',
  },
}));

export const SectionsSliderFooter = styled.div({
  scrollSnapAlign: 'start',
  height: 'auto',
  minHeight: '100%',
  flex: 'none',
});
