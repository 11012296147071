import styled from 'styled-components';
import { TypographyOld as Typography } from '@clikaliatech/clikalia-ui';
import { SubtitleSectionProps, BackButtonStyledProps } from './SubtitleSection.types';

export const SubtitleSectionWrapper = styled.div<Partial<SubtitleSectionProps>>(
  ({ theme: { breakpoints, spacing }, open }) => ({
    margin: spacing(0, 1.5),
    '& :first-child': {
      display: open ? 'none' : 'block',

      [breakpoints.up('sm')]: {
        display: 'block',
      },
    },
    '& :last-child': {
      display: open ? 'flex' : 'none',
      gap: spacing(1),

      [breakpoints.up('sm')]: {
        display: 'none',
      },
    },
  })
);

export const SubtitleStyled = styled(Typography)(({ theme: { spacing } }) => ({
  textAlign: 'center',
  margin: spacing(2, 0),
}));

export const BackButtonStyled = styled(Typography)<BackButtonStyledProps>(
  ({ theme: { spacing } }) => ({
    textAlign: 'center',
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    margin: spacing(2, 0),
    '&:focus-visible': { outline: '1px solid blue' },
  })
);
