import React from 'react';
import { TypographyOld as Typography } from '@clikaliatech/clikalia-ui';

import { ListItem, ListStyled } from './InformationList.styled';
import { InformationListProps } from './InformationList.types';

const InformationList: React.FC<InformationListProps> = ({ informationList }) => {
  return (
    <ListStyled>
      {informationList.map((element, index) => (
        <ListItem key={`informationItem-${index}`}>
          <Typography color="white" type={Typography.Types.B2Medium}>
            {element}
          </Typography>
        </ListItem>
      ))}
    </ListStyled>
  );
};

export default InformationList;
