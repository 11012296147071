import styled from 'styled-components';

export const ControlSliderArrowsWrapper = styled.div({
  display: 'flex',
  width: '100%',
  position: 'absolute',
  height: '100%',
  alignItems: 'center',

  /* smartphones, touchscreens */
  /* Source: https://ferie.medium.com/detect-a-touch-device-with-only-css-9f8e30fa1134 */
  '@media (hover: none) and (pointer: coarse)': {
    display: 'none',
  },
});

export const ControlButton = styled.button(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'absolute',
  left: theme.spacing(2),
  width: theme.spacing(4),
  height: theme.spacing(4),
  border: 'none',
  background: 'none',
  cursor: 'pointer',
  padding: 0,
  margin: 0,
  '&.right-control': {
    left: 'unset',
    right: theme.spacing(2),
  },
  '&::before': {
    content: "' '",
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.white,
    borderRadius: '50%',
    opacity: 0.5,
    transition: '0.3s ease opacity',
  },
  '&:hover::before': {
    opacity: 1,
  },
  '> span': {
    zIndex: 1,
    width: '100%',
    height: '100%',
    display: 'grid',
    alignContent: 'center',
  },
}));
