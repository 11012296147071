import { useState } from 'react';
import {
  Accordion,
  defaultTheme,
  Icon,
  TypographyOld as Typography,
} from '@clikaliatech/clikalia-ui';

import {
  FooterSectionDesktopStyled,
  FooterSectionStyled,
  FooterSectionTitleStyled,
  FooterSectionLinkStyled,
  FooterSectionMobileStyled,
  FooterSectionAccordionTitleStyled,
  FooterSectionAccordionBodyStyled,
} from './FooterSection.styled';
import { FooterSectionProps } from './FooterSection.types';

const FooterSection: React.FC<FooterSectionProps> = ({
  title,
  content,
  testId = 'footerSection',
}) => {
  const [openAccordion, setOpenAccordion] = useState(false);

  return (
    <>
      <FooterSectionDesktopStyled>
        <FooterSectionStyled data-testid={`${testId}SectionComponent`}>
          <FooterSectionTitleStyled>{title}</FooterSectionTitleStyled>
          {content.map(({ text, url }, index) => (
            <FooterSectionLinkStyled
              data-testid={`${testId}SectionComponent-link-${index}`}
              key={`footer-section-title-${url}-${index}`}
              href={url}
            >
              {text}
            </FooterSectionLinkStyled>
          ))}
        </FooterSectionStyled>
      </FooterSectionDesktopStyled>

      <FooterSectionMobileStyled>
        <Accordion
          open={openAccordion}
          onOpenChange={setOpenAccordion}
          buttonChildren={
            <FooterSectionAccordionTitleStyled>
              <Typography type={Typography.Types.B1Semibold} color={defaultTheme.palette.white}>
                {title}
              </Typography>
              <Icon
                name={openAccordion ? 'chevron-up' : 'chevron-down'}
                color={Icon.Colors.WHITE}
                variant={Icon.Variants.SHARP}
              />
            </FooterSectionAccordionTitleStyled>
          }
          testId={`${testId}-${title}-btn`}
        >
          <FooterSectionAccordionBodyStyled>
            {content.map(({ text, url }, index) => (
              <FooterSectionLinkStyled
                key={`footer-section-accordion-${url}-${index}`}
                href={url}
                data-testid={`${testId}SectionComponent-accordion-link-${index}`}
              >
                {text}
              </FooterSectionLinkStyled>
            ))}
          </FooterSectionAccordionBodyStyled>
        </Accordion>
      </FooterSectionMobileStyled>
    </>
  );
};

FooterSection.displayName = 'footerSection';

export default FooterSection;
