import styled from 'styled-components';
import { TypographyOld as Typography } from '@clikaliatech/clikalia-ui';

import { FAQsMobileContentProps, TitleStyledProps } from './FAQs.types';

const FAQsWrapper = styled.section(({ theme: { palette, spacing, typography, breakpoints } }) => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: palette.black,
  color: palette.white,
  padding: spacing(2, 0),
  width: '100%',
  minHeight: '500px',

  h3: {
    ...typography.s1Bold,
  },

  [breakpoints.up('sm')]: {
    h3: {
      ...typography.h3,
    },
  },
}));

const FAQsTitleStyled = styled(Typography)(({ theme: { palette, spacing } }) => ({
  display: 'flex',
  justifyContent: 'center',
  color: palette.salmon,
  margin: spacing(1, 0),
  cursor: 'pointer',
  textAlign: 'center',
}));

const FAQsContentWrapper = styled.div<Partial<FAQsMobileContentProps>>(
  ({ theme: { breakpoints }, visible }) => ({
    display: visible ? 'flex' : 'none',

    [breakpoints.up('sm')]: {
      width: '80%',
      margin: 'auto',
      display: 'grid',
      gridTemplateColumns: '1fr 10px 1fr',
      gridTemplateRows: 'auto',
    },
  })
);

const FAQsListWrapper = styled.div(({ theme: { breakpoints } }) => ({
  display: 'flex',
  height: 'auto',
  flexDirection: 'column',
  alignItems: 'start',
  justifyContent: 'start',
  [breakpoints.up('sm')]: {
    width: '100%',
    gridColumn: '1 / 2',
    cursor: 'pointer',
    alignItems: 'end',
  },
}));

const FAQsMobileContentWrapper = styled.div<Partial<FAQsMobileContentProps>>(
  ({ theme: { breakpoints }, visible }) => ({
    display: visible ? 'flex' : 'none',
    [breakpoints.up('sm')]: {
      display: 'none',
    },
  })
);

const FAQsDividerStyled = styled.hr(({ theme: { breakpoints, palette, spacing } }) => ({
  display: 'block',
  width: '100px',
  color: palette.white,
  margin: spacing(0, 2, 0),
  [breakpoints.up('sm')]: {
    display: 'none',
  },
}));

const FAQsVerticalDividerStyled = styled.hr(({ theme: { breakpoints, palette } }) => ({
  display: 'none',
  [breakpoints.up('sm')]: {
    display: 'block',
    width: '1px',
    height: '100%',
    color: palette.white,
    margin: 0,
  },
}));

const FAQsDescriptionPanelWrapper = styled.div(({ theme: { breakpoints } }) => ({
  display: 'none',
  [breakpoints.up('sm')]: {
    display: 'flex',
    gridColumn: '3 / 4',
  },
}));

const TitleStyled = styled.p<TitleStyledProps>(
  ({ theme: { typography, palette, spacing, breakpoints }, open, isFirstRender }) => ({
    ...typography.b1Regular,
    color: palette.lightSalmon,
    cursor: 'pointer',
    margin: open ? 0 : spacing(2),
    textAlign: 'start',
    '&:focus-visible': { outline: '1px solid blue' },
    [breakpoints.up('sm')]: {
      ...typography[open ? 'b1Bold' : 'b1Medium'],
      color: open ? palette.lightSalmon : palette.gray,
      margin: spacing(2),
      textAlign: 'end',
      '&:first-child': {
        ...typography[isFirstRender ? 'b1Bold' : 'b1Medium'],
        color: isFirstRender ? palette.lightSalmon : palette.gray,
      },
    },
  })
);

export {
  FAQsWrapper,
  FAQsTitleStyled,
  FAQsListWrapper,
  FAQsDescriptionPanelWrapper,
  FAQsContentWrapper,
  FAQsDividerStyled,
  FAQsMobileContentWrapper,
  TitleStyled,
  FAQsVerticalDividerStyled,
};
