import styled from 'styled-components';

export const FooterSectionsStyled = styled.div(({ theme }) => ({
  flex: '1 auto',
  maxWidth: 1000,
  display: 'grid',
  gridTemplateColumns: '100%',
  rowGap: theme.spacing(3),
  padding: theme.spacing(4, 6, 0, 6),
  alignContent: 'space-evenly',

  [theme.breakpoints.up('md')]: {
    gridTemplateColumns: '32% 32% 32%',
    columnGap: '4%',
  },
}));
