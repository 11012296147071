import styled from 'styled-components';

export const ViewHowWorkStepsWrapper = styled.div(({ theme }) => ({
  height: '300px',
  display: 'none',
  [theme.breakpoints.up('md')]: {
    display: 'block',
  },
}));

export const ViewHowWorkSlidersWrapper = styled.div(({ theme }) => ({
  flex: 1,
  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
}));
