import styled from 'styled-components';
import { ArrowWrapperProps, OverflowedWrapperProps } from './OverflowedWrapper.types';

const Wrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  height: '100%',
});

const ChildrenWrapper = styled.div<OverflowedWrapperProps>(({ maxHeight }) => ({
  display: 'flex',
  width: '100%',
  height: maxHeight ? `${maxHeight}` : '100%',
  overflow: 'auto',
  '&::-webkit-scrollbar': {
    width: 0,
    height: 0,
    background: 'transparent',
  },
}));

const ArrowWrapper = styled.div<ArrowWrapperProps>(({ visible }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  visibility: visible ? 'visible' : 'hidden',
  cursor: 'pointer',
}));

export { Wrapper, ChildrenWrapper, ArrowWrapper };
