import { useContext } from 'react';

import { FooterContext } from '../../../../Footer.context';
import { FooterLegalLinkStyled } from './FooterLegalLink.styled';
import { FooterLegalLinkProps } from './FooterLegalLink.types';

const FooterLegalLink: React.FC<FooterLegalLinkProps> = ({ text, url }) => {
  const { testId } = useContext(FooterContext);
  return (
    <FooterLegalLinkStyled data-testid={`${testId}LegalLink`} href={url}>
      {text}
    </FooterLegalLinkStyled>
  );
};

FooterLegalLink.displayName = 'footerLegalLink';

export default FooterLegalLink;
