import React from 'react';
import { Icon } from '@clikaliatech/clikalia-ui';

import { ControlButton, ControlSliderArrowsWrapper } from './ControlSliderArrows.styled';
import { ControlSliderArrowsProps } from './ControlSliderArrows.types';

const ControlSliderArrows: React.FC<ControlSliderArrowsProps> = ({
  testId = 'arrowsComponent',
  onPrev,
  onNext,
}) => {
  return (
    <ControlSliderArrowsWrapper data-testid={testId}>
      <ControlButton onClick={onPrev} data-testid={`${testId}-previousButton`}>
        <Icon name="chevron-left" variant={Icon.Variants.SHARP} color={Icon.Colors.PRIMARY} />
      </ControlButton>
      <ControlButton
        onClick={onNext}
        className="right-control"
        data-testid={`${testId}-nextButton`}
      >
        <Icon name="chevron-right" variant={Icon.Variants.SHARP} color={Icon.Colors.PRIMARY} />
      </ControlButton>
    </ControlSliderArrowsWrapper>
  );
};

export default ControlSliderArrows;
