import React, { useCallback, useMemo, useState } from 'react';

import SubtitleSection from './components/SubtitleSection';
import DescriptionPanel from './components/DescriptionPanel';
import MobileContent from './components/MobileContent';
import { FAQsProps } from './FAQs.types';
import {
  FAQsTitleStyled,
  FAQsWrapper,
  FAQsListWrapper,
  FAQsContentWrapper,
  FAQsDescriptionPanelWrapper,
  FAQsDividerStyled,
  FAQsMobileContentWrapper,
  TitleStyled,
  FAQsVerticalDividerStyled,
} from './FAQs.styled';
import { defaultTheme, TypographyOld as Typography } from '@clikaliatech/clikalia-ui';

const FAQs: React.FC<FAQsProps> = ({
  title,
  subtitle = null,
  backTitle,
  faqs,
  testId = 'faqsComponent',
}) => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const showMobileContent = activeIndex !== null;
  const isFirstRender = activeIndex === null;
  const lastFaqIndex = faqs.length - 1;

  const titleText = useMemo(() => {
    const [firstElement] = faqs;
    if (!firstElement) return '';
    return activeIndex === null ? firstElement.title : faqs[activeIndex].title;
  }, [faqs, activeIndex]);

  const description = useMemo(() => {
    const [firstElement] = faqs;
    if (!firstElement) return '';
    return activeIndex === null ? firstElement.description : faqs[activeIndex].description;
  }, [activeIndex, faqs]);

  const onClick = useCallback(
    (index: number | null) => {
      const newActiveIndex = index === activeIndex ? null : index;
      setActiveIndex(newActiveIndex);
    },
    [activeIndex]
  );

  return (
    <FAQsWrapper id={testId} data-testid={testId}>
      {title && (
        <FAQsTitleStyled type={Typography.Types.H3} color={defaultTheme.palette.salmon}>
          {title}
        </FAQsTitleStyled>
      )}
      <SubtitleSection
        open={showMobileContent}
        subtitle={subtitle}
        backTitle={backTitle}
        onBack={() => onClick(activeIndex)}
      />
      <FAQsMobileContentWrapper
        id="mobileContent"
        data-testid="mobileContent"
        visible={showMobileContent}
      >
        <MobileContent title={titleText} description={description} />
      </FAQsMobileContentWrapper>
      <FAQsContentWrapper visible={activeIndex === null}>
        <FAQsListWrapper id={`${testId}-list`}>
          {faqs.map((faq, index) => (
            <React.Fragment key={`fragment-${testId}-title-${index}`}>
              <TitleStyled
                tabIndex={0}
                key={faq.title}
                data-testid={`${testId}-list-item-${index}`}
                isFirstRender={(isFirstRender && index === 0) || index === activeIndex}
                open={index === activeIndex}
                onClick={() => onClick(index)}
              >
                {faq.title}
              </TitleStyled>
              {index !== lastFaqIndex && <FAQsDividerStyled />}
            </React.Fragment>
          ))}
        </FAQsListWrapper>
        <FAQsVerticalDividerStyled />
        <FAQsDescriptionPanelWrapper id="desktopContent" data-testid="desktopContent">
          <DescriptionPanel text={description} />
        </FAQsDescriptionPanelWrapper>
      </FAQsContentWrapper>
    </FAQsWrapper>
  );
};

export default FAQs;
