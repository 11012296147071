import React, { useContext } from 'react';
import { GetStaticProps } from 'next';
import { useTranslation } from 'next-i18next';
import { homeSections } from 'cross-config/utils/home/homeSections';
import {
  getStaticPropsCommonAcquisitionsPage,
  StaticCommonProps,
} from '@modules/acquisitions/getStaticPropsCommonAcquisitionsPage';
import HeadSEO from '@components/common/HeadSEO';
import HomeAcquisitionsSlider from '@components/HomeAcquisitions/HomeAcquisitionsSlider';
import HomeAcquisitionsCounter from '@components/HomeAcquisitions/HomeAcquisitionsCounter';
import HomeAcquisitionsReviews from '@components/HomeAcquisitions/HomeAcquisitionsReviews';
import HomeAcquisitionsWhatWeOffer from '@components/HomeAcquisitions/HomeAcquisitionsWhatWeOffer';
import HomeAcquisitionsFaqs from '@components/HomeAcquisitions/HomeAcquisitionsFaqs';
import WrapperCoverForm from '@components/common/WrapperCoverForm';

import {
  HomeLayout,
  HomeSection,
  LocalizationContext,
  BannerAdsMarketingBig,
  BannerAdsMarketingMiddle,
  BannerAdsMarketingSmall,
  BannerComponentsType,
  BannersContextProvider,
} from 'clikalia-web-components/src';
import useRoutesI18n from '@hooks/useRoutesI18n';
import { useRouter } from 'next/router';

import HomeAcquisitionsCMSBanner from '@components/HomeAcquisitions/HomeAcquisitionsCMSBanner';

const BannersAds: BannerComponentsType = {
  small: BannerAdsMarketingSmall,
  middle: BannerAdsMarketingMiddle,
  big: BannerAdsMarketingBig,
};

const Acquisitions = ({ siteConfig, banners }: StaticCommonProps) => {
  const { t } = useTranslation('acquisitions');
  const routes = useRoutesI18n();
  const router = useRouter();
  const { countryCode, urlLocale } = useContext(LocalizationContext);

  const sections = [
    <WrapperCoverForm key="homeAcquisitions-wrapper" />,
    <HomeAcquisitionsSlider key="homeAcquisitions-slider" />,
    <HomeAcquisitionsCounter key="homeAcquisitions-counter" />,
    <HomeAcquisitionsReviews key="homeAcquisitions-reviews" />,
    <HomeAcquisitionsWhatWeOffer key="homeAcquisitions-whatWeOffer" />,
    <HomeAcquisitionsFaqs key="homeAcquisitions-faqs" />,
  ];

  const sectionsFR = [
    <WrapperCoverForm key="homeAcquisitions-wrapper" />,
    <HomeAcquisitionsSlider key="homeAcquisitions-slider" />,
    <HomeAcquisitionsCounter key="homeAcquisitions-counter" />,
    <HomeAcquisitionsReviews key="homeAcquisitions-reviews" />,
    <HomeAcquisitionsFaqs key="homeAcquisitions-faqs" />,
  ];

  const sectionsByCountry = {
    ES: sections,
    FR: sectionsFR,
    PT: sections,
  };

  const tabs = [
    {
      label: t('acquisitions.tabs.buyHome.label'),
      href: '',
      indexRelated: 0,
    },
    {
      label: t('acquisitions.tabs.howItWorks.label'),
      href: homeSections.Acquisitions.howSellWorks,
      indexRelated: 1,
    },
    {
      label: t('acquisitions.tabs.whyClikalia.label'),
      href: homeSections.Acquisitions.whyClikalia,
      indexRelated: 2,
    },
    {
      label: t('acquisitions.tabs.reviews.label'),
      href: homeSections.Acquisitions.reviews,
      indexRelated: 3,
    },
    {
      label: t('acquisitions.tabs.faqs.label'),
      href: homeSections.Acquisitions.faqs,
      indexRelated: 5,
    },
  ];

  const onClickButton = () => {
    router.push(routes.form);
  };

  return (
    <>
      <HeadSEO
        title={t('acquisitions.header.home.title')}
        description={t('acquisitions.header.home.description')}
      />
      <BannersContextProvider banners={banners} bannerComponents={BannersAds}>
        <>
          <HomeAcquisitionsCMSBanner />
          <HomeLayout
            siteConfig={siteConfig}
            tabs={tabs}
            onClickButton={onClickButton}
            ctaButtonText={t('acquisitions.hero.ctaLabel')}
            showMobileCtaAt={[2, 3, 4, 5]}
            faqsIndexAt={5}
            tabsTestId="acquisitions"
            verticalPathname={`${routes.home.acquisitions}${urlLocale}`}
          >
            {sectionsByCountry[countryCode].map((section, index) => (
              <HomeSection key={`section_${index}`}>{section}</HomeSection>
            ))}
          </HomeLayout>
        </>
      </BannersContextProvider>
    </>
  );
};

export const getStaticProps: GetStaticProps = getStaticPropsCommonAcquisitionsPage;

export default Acquisitions;
