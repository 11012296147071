import styled from 'styled-components';

export const SocialWrapperStyled = styled.div(({ theme }) => ({
  color: theme.palette.white,
  fontWeight: 500,
  fontSize: theme.size(1.8),
  lineHeight: theme.size(2.8),
  display: 'flex',
  alignItems: 'center',
  height: '100%',
  alignContent: 'flex-end',
  justifyContent: 'space-around',
  gap: theme.spacing(1),
  padding: theme.spacing(2, 0, 0, 0),
  flexWrap: 'wrap',

  borderTop: '1px solid',
  borderColor: theme.palette.white,

  gridArea: 'social',

  p: {
    display: 'none',
  },

  [theme.breakpoints.up('md')]: {
    borderTop: 'none',

    justifyContent: 'flex-start',
    alignContent: 'flex-start',
    padding: theme.spacing(0, 0, 4, 2),
    p: {
      display: 'block',
    },
  },
}));

export const SocialMediaIconsWrapperStyled = styled.div(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(4),

  [theme.breakpoints.up('md')]: {
    gap: theme.spacing(2),
  },
}));

export const SocialMediaIconStyled = styled.a(({ theme }) => ({
  background: theme.palette.white,
  minWidth: 30,
  minHeight: 30,
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.black,
  opacity: 0.8,
  textDecoration: 'none',
}));
